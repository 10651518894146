import { isMobileMode } from 'core/helpers/mobile'

class Modal {
  constructor (id, opts, manager) {
    this.id = id
    this.opts = opts
    this.manager = manager
    this.active = false

    this.okConfig = opts.ok
    this.cancelConfig = opts.cancel

    this.bindEvents()
    this.createWrapper()
    this.createModal()
    this.createContent()
    this.createCloseButton()
    this.createActions()
    this.addPostMessageListeners()

    if (typeof opts.ready === 'function') {
      opts.ready(this.contentElement)
    }
  }

  addPostMessageListeners () {
    const handleMessage = () => {
      if (!event || !event.data || !event.data.type) {
        return
      }

      try {
        if (event.data.type === 'close') {
          this.handleClose()
        }
      } catch (e) {
        console.error(e)
      }
    }
    window.addEventListener('message', handleMessage.bind(this))
  }

  bindEvents () {
    this.handleClose = this.handleClose.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleOk = this.handleOk.bind(this)
  }

  setActive (state) {
    this.active = state
    if (state) {
      this.domElement.classList.add('feature-modal-active')
    } else {
      this.domElement.classList.remove('feature-modal-active')
    }
  }

  createWrapper () {
    this.domElement = document.createElement('div')
    this.domElement.classList.add('feature-modal-wrapper')
  }

  createModal () {
    this.modalElement = document.createElement('div')
    this.modalElement.classList.add('feature-modal')

    if (this.opts.className) {
      this.modalElement.classList.add(this.opts.className)
    }

    if (this.opts.fullscreenOnMobile) {
      if (isMobileMode()) {
        this.modalElement.classList.add('feature-modal-mobile')
      }
    }

    // Override style with user set styling.
    if (this.opts.style) {
      let styleEl = document.querySelector('style[data-id="feature-modal"]')
      if (styleEl) {
        document.head.removeChild(styleEl)
      }

      styleEl = document.createElement('style')
      styleEl.dataset.id = 'feature-modal'
      styleEl.innerHTML = this.opts.style
      document.head.appendChild(styleEl)
    }

    // Override width / height if fullScreen prop is set to true
    if (this.opts.fullScreen) {
      this.modalElement.style.width = '100%'
      this.modalElement.style.height = '100vh'
    }

    this.domElement.appendChild(this.modalElement)
  }

  createCloseButton () {
    if (this.opts.closeButton) {
      this.closeButtonElement = this.opts.closeButton
    } else {
      this.closeButtonElement = document.createElement('div')
      this.closeButtonElement.classList.add('feature-modal-close-button')
      const closeIcon = document.createElement('i')
      closeIcon.classList.add('fa-close', 'fa')
      this.closeButtonElement.appendChild(closeIcon)
      this.modalElement.appendChild(this.closeButtonElement)
    }

    this.closeButtonElement.addEventListener('click', this.handleClose)
  }

  createContent () {
    if (this.opts.content) {
      this.contentElement = document.createElement('div')
      this.contentElement.classList.add('feature-modal-content')
      if (typeof this.opts.content === 'object') {
        this.contentElement.appendChild(this.opts.content)
      } else {
        this.contentElement.innerHTML = this.opts.content
      }
      this.modalElement.appendChild(this.contentElement)
    }
  }

  createActions () {
    if (!this.cancelConfig && !this.okConfig) {
      return
    }
    this.actionsElement = document.createElement('div')
    this.actionsElement.classList.add('feature-modal-actions')
    if (this.cancelConfig) {
      this.cancelButton = document.createElement('button')
      this.cancelButton.classList.add('feature-modal-cancel-button')
      this.cancelButton.innerHTML = this.cancelConfig.label || 'Cancel'
      this.cancelButton.addEventListener('click', this.handleCancel)
      this.actionsElement.appendChild(this.cancelButton)
    }
    if (this.okConfig) {
      this.okButton = document.createElement('button')
      this.okButton.classList.add('feature-modal-ok-button')
      this.okButton.innerHTML = this.okConfig.label || 'Ok'
      this.okButton.addEventListener('click', this.handleOk)
      this.actionsElement.appendChild(this.okButton)
    }
    this.modalElement.appendChild(this.actionsElement)
  }

  handleClose (e) {
    this.closeButtonElement.removeEventListener('click', this.handleClose)
    this.manager.closeModal(this.id, this.opts.onClose)
  }

  handleCancel () {
    if (typeof this.cancelConfig.callback === 'function') {
      const mayClose = this.cancelConfig.callback()
      if (mayClose !== false) {
        this.handleClose()
      }
    }
  }

  handleOk () {
    if (typeof this.okConfig.callback === 'function') {
      const mayClose = this.okConfig.callback()
      if (mayClose !== false) {
        this.handleClose()
      }
    }
  }
}

export default Modal
