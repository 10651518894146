import Handlebars from 'handlebars'

import CoreLayoutHelper from 'core/editor/helpers/layout-helper'

import Layouts from 'editor/managers/layouts'

class LayoutHelper extends CoreLayoutHelper {
  static hasLayoutFile (layoutsSettings, propName, fileName) {
    return super.hasLayoutFile(layoutsSettings, propName, fileName, Layouts)
  }

  static internalGetLayoutObj (layoutsObj, fileName, propName) {
    if (!layoutsObj) {
      return null
    }

    const layoutObjObject = Layouts.getLayoutObject()
    let layout
    if (layoutObjObject) {
      // load from layout definition
      layout = layoutsObj.id ? layoutObjObject.getById(layoutsObj.id) : layoutObjObject.get(layoutsObj.path)
    } else {
      // load inline layout
      layout = layoutsObj
    }

    if (layout) {
      if (!layout.compiledLayouts) {
        layout.compiledLayouts = {}
      }

      if (fileName) {
        if (!layout.html_templates[fileName] && !layout.compiledLayouts[fileName] && (fileName === 'left' || fileName === 'right')) {
          if (layout.html_templates.index || layout.compiledLayouts.index) {
            fileName = 'index'
          }
        }

        if (!layout.compiledLayouts[fileName]) {
          if (!layout.html_templates || !layout.html_templates[fileName]) {
            throw new Error(`${layout.path}/${fileName}.html not found`)
          }
          const html = layout.html_templates[fileName].trim()
          layout.compiledLayouts[fileName] = Handlebars.compile(html)
        }
      } else {
        // fallback to default layout
        if (!layout.html && layout.html_templates) {
          layout.html = layout.html_templates[Object.keys(layout.html_templates)[0]]
        }
        if (!layout.compiledLayouts.index && layout.html) {
          // compile index.html
          const html = layout.html.trim()
          layout.compiledLayouts.index = Handlebars.compile(html)
        }
      }
    }
    return layout
  }
}

export default LayoutHelper
