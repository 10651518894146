import each from 'lodash/each'

class RuntimeFeatures {
///////////////////
  static loadFeaturesRuntime (controller, routeParams) {
    if (window.__publishedFeatures) {
      // only used in layout maker
      window.__publishedFeatures.forEach(name => this.getActiveFeature(controller, name, false, false))
    } else {
      each(window.__features, (featureClass, name) => {
        this.getActiveFeature(controller, name, false, false)
      })
    }
    this.openPage(controller, routeParams)
  }
///////////
}
export default RuntimeFeatures
