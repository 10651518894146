import BaseControl from 'editor/controls/base-control'

import $ from 'jquery'

import each from 'lodash/each'

class ListControl extends BaseControl {
  constructor (controller, Layout, view = null) {
    super(controller, () => Layout, controller.model, 'list')
    this.view = view
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

    if (this.controller.editorContext && this.controller.editable()) {
      const addButton = element.querySelector('.kv-add-item-btn')
      if (addButton) {
        addButton.addEventListener('click', this.controller.addItemClick)
      }

      $(element)
        .find(' > *[data-type="list"]')
        .sortable({
          animation: 300,
          items: '*[data-type="item"]',
          handle: '.kv-drag-handle',
          tolerance: 'intersect',
          forcePlaceholder: true,
          revert: 300,
          start: (event, ui) => {
            this.controller.editorContext.selectionManager.disable()
            $(this.domElement)
              .find('.kv-selected')
              .removeClass('kv-selected')
            ui.item.addClass('kv-selected')
            this.domElement.classList.add('kv-dragging')
            ui.item.data('index', ui.item.index())

            ui.placeholder[0].style.backgroundColor = 'black'
            ui.placeholder[0].style.opacity = 1
          },
          stop: (event, ui) => {
            this.controller.editorContext.selectionManager.enable()
            this.domElement.classList.remove('kv-dragging')
            const oldIndex = ui.item.data('index')
            const newIndex = ui.item.index()

            this.controller.reorderItem(oldIndex, newIndex)
            ui.item.removeData('index')
            $(this.domElement)
              .find('.kv-selected')
              .removeClass('kv-selected')
            ui.item.removeClass('kv-selected')

            event.stopImmediatePropagation()
          }
        })

      const handle = $('*[data-type="list"] .kv-drag-handle', element)
      handle.mousedown(() => {
        this.controller.editorContext.selectionManager.disable()
      })
      handle.mouseup(() => {
        this.controller.editorContext.selectionManager.enable()
      })

      if (data.isRemoteList) {
        const addContainer = element.querySelector('.add-container')
        if (addContainer) {
          addContainer.remove()
        }
      }
    } else if (!this.controller.editorContext) {
      const swiper = element.querySelector('.swiper-container')
      if (swiper) {
        element.classList.add('swiper-section')
        const slides = swiper.children[0].children
        each(slides, slide => {
          ;[...slide.classList].map(className => {
            if (className.indexOf('col-') === 0) {
              slide.classList.remove(className)
            }
          })
          slide.classList.remove('content-item')
          slide.classList.remove('content-container')
          slide.classList.add('swiper-slide')
        })

        this.swiper = new this.controller.imports.Swiper(swiper, {
          slidesPerView: 1.3,
          centeredSlides: true,
          spaceBetween: 30,
          autoHeight: true,
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        })
      }
    } else {
      const swiper = element.querySelector('.swiper-container')
      if (swiper) {
        swiper.remove()
      }
    }
  }
}
export default ListControl
