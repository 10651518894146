import { Localizations } from '@eig-builder/module-localization'

Localizations.append({
  shared: {
    ada: {
      skipToMainContent: 'Skip to main content'
    },
    createSite: {
      defaultGlobalBinding: {
        callToAction: {
          title: 'Call'
        },
        hours: {
          closed: 'Closed'
        },
        legal: {
          privacyPolicy: {
            agreeButtonText: "I'm okay with that",
            bannerText: 'This site uses cookies'
          }
        }
      },
      uri: {
        store: 'store',
        storeProduct: 'store/product',
        blog: 'blog',
        blogPost: 'blog/post'
      },
      pages: { // these pages are being used as title but also kebab styled as page:contact-us.
        home: 'Home',
        menu: 'Menu',
        about: 'About',
        contact: 'Contact',
        services: 'Services',
        pricelist: 'Pricelist',
        contactus: 'Contact us',
        pricing: 'Pricing',
        events: 'Events',
        gallery: 'Gallery',
        location: 'Location',
        contactme: 'Contact me',
        aboutme: 'About me',
        getintouch: 'Get in touch',
        shows: 'Shows',
        store: 'Store',
        storeProduct: 'Product',
        blog: 'Blog',
        blogPost: 'Blog Post',
        ourdestinations: 'Our destinations'
      }
    }
  }
})
