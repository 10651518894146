const progressCircleHelper = (property, classList, value, dashoffset) => {
  return `<div
    data-type="progress"
    data-property="${property}"
    class="progress-wrapper ${classList}"
    style="position:relative;"
  >
    <p
      style="
        position:absolute;
        text-align:center;
        width:100%;
        line-height:20px;
        top:calc(50% - 10px);
        font-size:1.5rem;
      "
    >
      ${value}%
    </p>
    <svg
      class="progress"
      style="
        margin:0 auto;
        width:150px;
        height:150px;
        background-color: transparent;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      "
      width="150"
      height="150"
      viewBox="0 0 150 150"
    >
      <circle
        class="progress__meter"
        cx="75"
        cy="75"
        r="71"
        stroke-width="8" />
      <circle
        class="progress__value"
        cx="75"
        cy="75"
        r="71"
        stroke-width="8"
        style="stroke-dasharray: 446.106; stroke-dashoffset: ${dashoffset};"/>
    </svg>
  </div>`
}

export default progressCircleHelper
