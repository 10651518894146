class HeaderButtonsSDK {
  constructor (customHeaderButtons) {
    this.customHeaderButtons = customHeaderButtons
  }

  add (id, html) {
    this.customHeaderButtons.add(id, html)
  }

  remove (id) {
    this.customHeaderButtons.remove(id)
  }

  forEach (id, onVisitElement) {
    this.customHeaderButtons.iterateAll(id, onVisitElement)
  }
}

export default HeaderButtonsSDK
