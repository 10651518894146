const freshClassList = (classList, prop) => {
  // So by default this allready has a primary, ghost or secondary class. We have to get rid of it and add the new one
  const listWithoutClassesKnown = classList
    .split(' ')
    .filter(
      classes =>
        [
          'button-primary',
          'button-tertiary',
          'button-quaternary',
          'button-secondary',
          'button-ghost-primary',
          'button-ghost-secondary',
          'button-ghost-tertiary',
          'button-ghost-quaternary'
        ].indexOf(classes) === -1
    )
    .join(' ')

  return `${listWithoutClassesKnown} ${prop.class}`
}

const buildClassAttribute = (prop, classList) => {
  // freshClassList - will strip the primary / secondary / ghost classes from the classList
  // - so we can use the ones passed in the props

  const buttonClass = prop.buttonClass && prop.buttonClass.includes('button') ? (prop.buttonClass + prop.colorClass) : prop.buttonClass + ' button-primary'
  return ` class="
    ${freshClassList(classList, prop)}
    button-dynamic-buttons kv-button-instant-edit
    ${buttonClass}
  "`
}

export default buildClassAttribute
