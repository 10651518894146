import { getApiKey } from '@eig-builder/core-utils/helpers/api-key-helper'

/**
 * Use this class to ensure Google Maps API javascript is
 * loaded before running any google map specific code.
 */
class GoogleMapsApi {
  constructor () {
    this.apiKey = getApiKey('responsive_editor_google_maps_static_api')

    // Set a globally scoped callback if it doesn't already exist.
    if (!window._GoogleMapsApi) {
      this.callbackName = '_GoogleMapsApi.mapLoaded'
      window._GoogleMapsApi = this
      window._GoogleMapsApi.mapLoaded = this.mapLoaded.bind(this)
    }
  }

  /**
   * Load the Google Maps JS API
   */
  load () {
    if (!this.promise) {
      this.promise = new Promise(resolve => {
        this.resolve = resolve

        if (typeof window.google === 'undefined' && document && document.createElement) {
          const script = document.createElement('script')
          script.src = `//maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places&callback=${this.callbackName}&v=3.35` // Support for the new renderer.
          script.async = true
          script.defer = true
          document.body.append(script)
        } else {
          this.resolve()
        }
      })
    }
    return this.promise
  }

  /**
   * Globally scoped callback for the map loaded.
   */
  mapLoaded () {
    if (this.resolve) {
      this.resolve()
    }
  }
}

// Make sure only instance can exist of the GoogleMapsApi class.
const instance = new GoogleMapsApi()
// Object.freeze(instance) // We don't want this to be immutable, since the load events can't add a promise to the class scope.

export default instance
