class SwiperControl {
  constructor (element, controller) {
    this.element = element
    this.controller = controller
  }

  initialize () {
    const mainSliderSelector = this.element.querySelector('.main-slider')
    const navSliderSelector = this.element.querySelector('.nav-slider')

    // if (element.dataset.type === 'swiper') {
    if (mainSliderSelector) {
      const interleaveOffset = 0.5

      // Main Slider
      const mainSliderOptions = {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 3000
        },
        loopAdditionalSlides: 10,
        grabCursor: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          init: function () {
            this.autoplay.stop()
          },
          imagesReady: function () {
            this.el.classList.remove('loading')
            this.autoplay.start()
          },
          slideChangeTransitionEnd: function () {
            const swiper = this
            const captions = swiper.el.querySelectorAll('.caption')
            for (let i = 0; i < captions.length; ++i) {
              captions[i].classList.remove('show')
            }
            if (swiper.slides[swiper.activeIndex]) {
              swiper.slides[swiper.activeIndex].querySelector('.caption').classList.add('show')
            }
          },
          progress: function () {
            const swiper = this
            for (let i = 0; i < swiper.slides.length; i++) {
              const slideProgress = swiper.slides[i].progress
              const innerOffset = swiper.width * interleaveOffset
              const innerTranslate = slideProgress * innerOffset
              swiper.slides[i].querySelector('.slide-bgimg').style.transform =
                'translate3d(' + innerTranslate + 'px, 0, 0)'
            }
          },
          touchStart: function () {
            const swiper = this
            for (let i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = ''
            }
          },
          setTransition: function (speed) {
            const swiper = this
            for (let i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + 'ms'
              swiper.slides[i].querySelector('.slide-bgimg').style.transition = speed + 'ms'
            }
          }
        }
      }
      const mainSlider = new this.controller.imports.Swiper(mainSliderSelector, mainSliderOptions)

      // Navigation Slider
      const navSliderOptions = {
        loop: true,
        loopAdditionalSlides: 10,
        speed: 1000,
        spaceBetween: 5,
        slidesPerView: 5,
        centeredSlides: true,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        direction: 'vertical',
        on: {
          imagesReady: function () {
            this.el.classList.remove('loading')
          },
          click: function () {
            mainSlider.autoplay.stop()
          }
        }
      }
      const navSlider = new this.controller.imports.Swiper(navSliderSelector, navSliderOptions)

      // Matching sliders
      mainSlider.controller.control = navSlider
      navSlider.controller.control = mainSlider
    }
  }
}
export default SwiperControl
