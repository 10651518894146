import GlobalProperties from 'core/helpers/global/global-properties'

/**
 *
 * @param {*} url - normal url of the site
 * @return the preview url if needed
 */
const getPageUrl = uriPath => {
  let url = uriPath

  if (typeof window === 'undefined' && GlobalProperties.routePrefix) {
    // <-- TODO: this is always false. Make sure GlobalProperties has a routePrefix aka SiteSecret
    const prefix = GlobalProperties.routePrefix
    url = prefix + url
  } else if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.hostname.indexOf('preview-express') === 0
  ) {
    const siteId = window.location.pathname.match('/[0-9a-z]+')[0]
    url = siteId + url
  }

  //   url = 'testprefix' + url

  return url
}

export { getPageUrl }
