function isObject (item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

export function merge (target, source) {
  const output = Object.assign({}, target)
  if (isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!target.hasOwnProperty(key)) {
          output[key] = source[key]
        } else {
          output[key] = merge(target[key], source[key])
        }
      } else {
        output[key] = source[key]
      }
    })
  }
  return output
}

export function mergeMissing (target, source) {
  if (isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!target.hasOwnProperty(key)) {
          target[key] = source[key]
        } else {
          merge(target[key], source[key])
        }
      } else {
        if (!target.hasOwnProperty(key)) {
          target[key] = source[key]
        }
      }
    })
  }
  return target
}
