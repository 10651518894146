class StorageApi {
  constructor (siteController) {
    this.siteController = siteController
  }

  set (key, value) {
    if (!this.siteController.editorContext) {
      console.error('can only set data from editor')
    }
    this.siteController.editorContext.updatePropertyInstant(this.siteController.model.featureStorage, key, value)
    return Promise.resolve()
  }

  get (key) {
    const obj = this.siteController.model.featureStorage
    const value = obj && obj[key]
    return Promise.resolve(value)
  }

  remove (key) {
    this.set(key, undefined)
  }

  async forEach (callback) {
    const keys = Object.keys(this.siteController.model.featureStorage)
    const values = await Promise.all(keys.map(key => this.get(key)))
    values.forEach((value, i) => callback(value, keys[i], i))
  }
}

export default StorageApi
