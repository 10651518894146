function StringParser (stringToParse) {
  const str = stringToParse
  let pos = 0
  let outputStr = ''

  this.endOfString = () => pos >= str.length
  this.peekChar = () => str[pos]
  this.getChar = () => {
    const c = str[pos++]
    outputStr += c
    return c
  }

  this.getOutput = () => {
    const o = outputStr
    outputStr = ''
    return o
  }

  this.getParams = () => {
    let nestcount = 0
    let paramStr = ''
    const paramArr = []
    while (!this.endOfString()) {
      const c = this.getChar()
      if (c === '(') {
        nestcount++
      } else if (nestcount === 0 && c === ',') {
        paramArr.push(paramStr)
        paramStr = ''
        continue
      } else if (c === ')' && nestcount-- === 0) {
        break
      }
      paramStr += c
    }
    paramArr.push(paramStr)
    return paramArr
  }

  this.getWord = () => {
    let wordStr = ''
    while (!this.endOfString()) {
      const c = this.peekChar()
      if ((c >= 'A' && c <= 'Z') || (c >= 'a' && c <= 'z')) {
        wordStr += this.getChar()
      } else {
        break
      }
    }
    return wordStr
  }
}

export default StringParser
