export const SECTION_STATUS = {
  EDITABLE: 1,
  READONLY: 2,
  SECTION_PREVIEW: 3
}

export const KEYCODES = {
  ESCAPE: 27,
  ENTER: 13,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  DELETE: 46,
  BACKSPACE: 8,
  TAB: 9,
  SPACE: 32
}
