import each from 'lodash/each'

import Store from '@eig-builder/core-utils/store'
import GlobalProperties from 'core/helpers/global/global-properties'

import { classes } from 'core/utils'

import EditorFeatures from './instance/editor'
import EditorAndPublisherFeatures from './instance/editor-and-publisher'
import RuntimeFeatures from './instance/runtime'
import { SECTION_STATUS as STATUS } from 'core/helpers/global/enums'

import ActiveFeature from './active-feature'

import { getFeatureSettings, getIsOnboarding } from 'editor/managers/features/feature-helpers'

class Features extends classes(EditorFeatures, EditorAndPublisherFeatures, RuntimeFeatures) {
  static killAll () {
    ActiveFeature.killAll()
  }

  static openPage (siteController, routeParams) {
    let pageType
    const pageFeatures = {}
    siteController.getActivePageController().forEachContentControls(contentControl => {
      this.loopFeatureInstances(contentControl, (featureName, featureInstance) => {
        pageFeatures[featureName] = featureInstance
      })
    })

    each(pageFeatures, instance => {
      if ((pageType = siteController.getActivePageModel().pageType) && instance.onOpenPageType) {
        instance.onOpenPageType(siteController.getActivePageControl(), pageType, routeParams, Object.keys(pageFeatures))
      }
    })
  }

  static afterRenderCustomHeaderButton (id, element) {
    each(ActiveFeature.getActiveFeatures(false), feature => {
      if (feature.FeatureClass.afterRenderCustomHeaderButton) {
        feature.FeatureClass.afterRenderCustomHeaderButton(id, element)
      }
    })
  }

  static forEachActiveFeatureInstance (callback) {
    each(ActiveFeature.getActiveFeatures(false), active => {
      active.instances.forEach(callback)
    })
  }

  static removeFeaturesForControl (control) {
    if (!control.features) {
      return
    }

    Object.keys(control.features).forEach(key => {
      const activeFeature = this.getActiveFeature(control.controller, key)

      // Remove all feature instances with this control as a view
      activeFeature.instances.forEach((instance, index) => {
        if (instance.view && instance.view === control) {
          activeFeature.instances.splice(index, 1)
        }

        const idx = activeFeature.featureControlRefs.indexOf(control.features)
        if (idx > -1) {
          activeFeature.featureControlRefs.splice(idx, 1)
        }
        if (activeFeature.instances.length === 0) {
          activeFeature.dispose()
        }
      })
    })
  }

  static getActiveFeature (controller, featureName, isForPreview, isOnboarding, addedFeatureNames, depth = 0) {
    if (depth > 10) {
      console.error('recursion in dependancy tree in features')
      return null
    }

////////////////////
////////////////////////////////////////////////////////////////
/////////////////////////////////////////
/////
/////////////

    const map = ActiveFeature.getActiveFeatures(isForPreview)

    if (!map[featureName]) {
      const FeatureClass = window.__features && window.__features[featureName]
      if (FeatureClass) {
        let isGlobal = false
        let dependingFeatures = []
        const featureSettings = getFeatureSettings(featureName)
        if (featureSettings && featureSettings.settings) {
          const settings = featureSettings.settings
          isGlobal = settings.isGlobal
          if (settings.dependingGlobalFeatures && Array.isArray(settings.dependingGlobalFeatures)) {
            // load depending features
            dependingFeatures = settings.dependingGlobalFeatures.map(i =>
              this.getActiveFeature(controller, i, isForPreview, isOnboarding, addedFeatureNames, depth + 1)
            )
          }
        }

        if (addedFeatureNames) {
          addedFeatureNames.push(featureName)
        }
        const activeFeature = new ActiveFeature(
          controller,
          FeatureClass,
          featureName,
          isForPreview,
          isOnboarding,
          dependingFeatures
        )
        if (isGlobal || featureName === 'store-core') {
          activeFeature.getGlobalInstance()
        }
        map[featureName] = activeFeature
      } else {
        // Feature somethimes don't exist in a published site. In the case of legal, it is used in footers but only published if you use this feature. It should return null in this function by design.
        return null
      }
    }
    return map[featureName]
  }

  static constructFeature (featureName, contentControl, isForPreview, addedFeatureNames) {
    const controller = contentControl.controller
////////////////////
///////////////////////////////////////////////////
///////////////////
//////////////////////////////////////////////////////////
/////

////////////////////////////////////////////////////////////////////////////////////////////
////////////
/////
/////////////

    const activeFeature = this.getActiveFeature(
      controller,
      featureName,
      isForPreview,
      getIsOnboarding(),
      addedFeatureNames
    )
    if (activeFeature) {
      return activeFeature.createInstance(contentControl)
    }
  }

  static internalLoopFeatureInstances (control, callback, onlyNew = false) {
    if (!control.features) {
      control.features = {}
    }

    const model = control.model
    let isForPreview = false

    let features = model.features // runtime we get the features like this
    if (!model.features) {
//////////////////////
/////////////////////////////////////////////////////////
//////////////
///////
///////////////
      const sectionController = control.controller.getSectionController()
      let sectionLayout
      try {
        sectionLayout = sectionController.getSectionLayoutObj()
      } catch (ex) {
        console.error('Could not load layout', ex)
      }
      isForPreview = sectionController.model._isForPreview
      features = sectionLayout && sectionLayout.metadata && sectionLayout.metadata.features
    }

    if (features) {
      for (let i = 0; i < features.length; i++) {
        const featureName = features[i]

        let featureInstance = control.features[featureName]
        if (!featureInstance) {
          // initialize
          featureInstance = this.constructFeature(featureName, control, isForPreview, null, getFeatureSettings)
          if (featureInstance) {
            // moved to activeFeature : control.features[featureName] = featureInstance
            if (onlyNew) {
              callback(featureName, featureInstance)
            }
          }
        }

        if (featureInstance && !onlyNew) {
          callback(featureName, featureInstance)
        }
      }
    }
  }

  static loopFeatureInstances (control, callback) {
    if (!control.features) {
      this.internalLoopFeatureInstances(control, callback)
    } else {
      for (const name in control.features) {
        callback(name, control.features[name])
      }
    }
  }

  static getRenderModel (contentControl, model, globalBinding, opts = {}) {
    let result = null
    this.loopFeatureInstances(contentControl, (featureName, featureInstance) => {
      if (featureInstance.getRenderModel) {
        const isForPreview = contentControl.controller.getSectionController().model._isForPreview
        const isForOnboarding = getIsOnboarding()

        const status = {
          thumbnail: isForPreview,
          onboarding: isForOnboarding,
          preview: GlobalProperties.renderingPreview,
          editable: GlobalProperties.editable, // editable === (!finalMail && !preview && !thumbnail) so editable is true in onboarding and editor modus
          ...opts
        }

        result = {
          ...result,
          ...featureInstance.getRenderModel(contentControl, model, globalBinding, status)
        }
      }
    })
    return result
  }

  static afterRender (contentControl, data, element) {
    this.loopFeatureInstances(contentControl, (featureName, featureInstance) => {
      // rerender
      if (featureInstance.afterRender) {
        const wait = (featureInstance.getLoadPromise && featureInstance.getLoadPromise()) || Promise.resolve()
        wait.then(_ => {
          try {
            featureInstance.afterRender(contentControl, element, data)
          } catch (ex) {
            console.error(`afterRender failed of ${featureName}\n` + ex)
          }
        })
      }
    })
  }

  static initializeFeatures (contentControl, data, isEditor = IS_EDITOR) {
    try {
      this.internalLoopFeatureInstances(
        contentControl,
        (featureName, featureInstance) => {
          const featureSettings = getFeatureSettings(featureName)

          if (featureInstance.initializeForSection) {
            const wait = (featureInstance.getLoadPromise && featureInstance.getLoadPromise()) || Promise.resolve()
            wait.then(_ => {
              const isForPreview = contentControl.controller.getSectionController().model._isForPreview
              featureInstance.initializeForSection(contentControl, data, isForPreview)
            })
          }

          if (featureInstance.updateProperty) {
            contentControl.updateFeatureProperty = featureInstance.updateProperty.bind(featureInstance)
          }

          if (isEditor) {
            const isPreview = contentControl.controller.status === STATUS.SECTION_PREVIEW
            setTimeout(() => {
              // Make sure this is done after the section has been initialized
              const hasPage = Features.checkIfAddedPages(contentControl.controller.getSiteController(), [featureSettings.settings])
              if (!hasPage && !isPreview) {
                Features.addPageTypesToSite(contentControl.controller.getSiteController(), [featureSettings.settings], undefined, false)
              }
            }, 0)
          }
        },
        true
      )
    } catch (ex) {
      console.error('error initializing features', ex)
    }
  }

  static onModelChanged (contentControl, model) {
    this.internalLoopFeatureInstances(contentControl, (featureName, featureInstance) => {
      if (featureInstance.onModelChanged) {
        featureInstance.onModelChanged(model)
      }
    })
  }

  static mount (contentControl) {
    this.loopFeatureInstances(contentControl, (featureName, featureInstance) => {
      if (featureInstance.mount) {
        featureInstance.mount()
      }
    })
  }
}
Features.collectPublish
Features.publishedFeatures = {}
Features.addedScriptNodes = {}

export default Features
