class SmartArray {
  constructor () {
    this.collection = []
    this.length = 0
  }

  push (item) {
    this.collection.push(item)
    this.length = this.collection.length
    return this.length - 1
  }

  forEach (callback) {
    this.collection.forEach((item, i) => {
      callback(item, i)
    })
  }

  getPrevious (index) {
    const prevIndex = index - 1
    if (prevIndex < 0) {
      return null
    }
    return this.collection[prevIndex]
  }

  shiftByIndex (index) {
    const itemToRemove = this.collection[index]
    this.collection = this.removeByIndex(index)
    this.length = this.collection.length
    return itemToRemove
  }

  removeByIndex (index) {
    return [...this.collection.slice(0, index), ...this.collection.slice(index + 1, this.collection.length)]
  }
}

export default SmartArray
