import Handlebars from 'handlebars'
import 'core/editor/helpers/handlebars'

import './maps/maps'
import './helpers/popover'

import get from 'lodash/get'

import GlobalProperties from 'core/helpers/global/global-properties'

// Handlebars helpers and utils
import controlHelper from './helpers/control'
import subControlHelper from './helpers/sub-control'
import socialProviders from './helpers/social-providers'
import textHelpers from './helpers/text'
import priceHelper from './helpers/price'
import contactHelpers from './helpers/contact'
// import progressHelper from './helpers/progress'
import dropdownHelper from './helpers/dropdown'
import selectBoxHelper from './helpers/select-box'
import progressCircleHelper from './helpers/progress-circle'
import countdownHelper from './helpers/countdown'
import youtubeHelper from './helpers/youtube'
import facebookVideoHelper from './helpers/facebook'
import videoHelper from './helpers/video'
import imageHelper from './helpers/image'
import imageMaskHelper from './helpers/image-mask'
import socialHelper from './helpers/social'
import linkButtonHelper from './helpers/link-button-helper'
import formButtonHelper from './helpers/form-button-helper'
import toggleIsOn from 'core/editor/helpers/handlebars/toggle-helper'
import iconHelper from './helpers/icon'
import calculateHelper from './helpers/calculate'
import repeatHelper from './helpers/repeat'
import paginationHelper from './helpers/pagination'
import LoaderTemplate from './templates/loader.tpl'
import encodeToString from 'core/helpers/data/html/html-encode'

// import legalHelper from './helpers/legal-helper'

import { hasData, constructData, getHref, registerHelperWithDataAsFirstArgument } from './utils'

const { textHelper, textHelperHandlebars } = textHelpers

Handlebars.registerHelper('calculate', (a, operator, b, block) => calculateHelper(a, operator, b, block))
Handlebars.registerHelper('calc', (a, operator, b, block) => calculateHelper(a, operator, b, block))

Handlebars.registerHelper('repeat', (n, block) => repeatHelper(n, block))

Handlebars.registerHelper('pagination', data => new Handlebars.SafeString(paginationHelper(data)))

Handlebars.registerHelper('dropdown', (options, classList, defaultIndex, data) => {
  return new Handlebars.SafeString(dropdownHelper(options, classList, defaultIndex, data))
})

// Generates a selectbox with options, based on an array of items
Handlebars.registerHelper('selectBox', (options, classList, defaultValue, data) => {
  return new Handlebars.SafeString(selectBoxHelper(options, classList, defaultValue, data))
})

Handlebars.registerHelper('control', (name, index, control, data) => {
  return new Handlebars.SafeString(controlHelper(name, index, control, data))
})

Handlebars.registerHelper('subControl', (name, index, control) => {
  return new Handlebars.SafeString(subControlHelper(name, index, control))
})

Handlebars.registerHelper('text', (property, nodeName, classList, data) => {
  if (nodeName.data) {
    // Returns a template string
    return new Handlebars.SafeString(textHelperHandlebars(property, nodeName, classList, data))
  } else {
    // This will return an editable node (check helper util - buildEditableNode)
    return new Handlebars.SafeString(textHelper(property, nodeName, classList, data))
  }
})

Handlebars.registerHelper('priceInput', (property, nodeName, classList, data) => {
  return new Handlebars.SafeString(priceHelper(property, nodeName, classList, data))
})

Handlebars.registerHelper('progress', (property, classList, data) => {
  if (!data && classList && classList.data) {
    data = classList
    classList = ''
  }

  if (!data || !data.data) {
    return ''
  }

  const binding = data.data.root
  const value = parseInt(
    get(binding, property)
      .toString()
      .replace(/[^0-9.]/g, '')
  ) // Make sure values are always integers and NO html tags are in here.

  return new Handlebars.SafeString(
    `<div
      data-type="progress"
      data-property="${property}"
      class="progress-wrapper ${classList}">
      <div class="progress">
        <div class="progress-bar"
          role="progressbar"
          style="width: ${value}%"
          aria-valuenow="${value}"
          aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>
    </div>`
  )
})

Handlebars.registerHelper('groupedHours', function (data) {
  const root = data.data.root
  const hours = root.global.openingHours
  const days = root.days

  let previousDayHours = null
  // Group consecutive days that have the same opening hours
  const groupedDays = []
  Object.keys(hours).forEach(key => {
    const item = hours[key]
    const day = JSON.stringify(item)

    if (day === previousDayHours) {
      const previousDay = groupedDays[groupedDays.length - 1]
      previousDay.dayTitle.push(days[key])
    } else {
      groupedDays.push({
        openingHours: item,
        dayTitle: [days[key]]
      })
    }

    previousDayHours = day
  })

  return groupedDays
    .map(day => {
      const mappedDay = {}
      const days = day.dayTitle
      if (days.length > 1) {
        mappedDay.dayTitle = days[0] + ' - ' + days[days.length - 1]
      } else {
        mappedDay.dayTitle = days[0]
      }

      mappedDay.openingHours = day.openingHours
      return data.fn(mappedDay, { data: mappedDay })
    })
    .join('')
})

Handlebars.registerHelper('progressCircle', (property, classList, data) => {
  if (!data && classList && classList.data) {
    data = classList
    classList = ''
  }

  if (!data || !data.data) {
    return ''
  }

  const binding = data.data.root
  const value = get(binding, property)
  const RADIUS = 71
  const CIRCUMFERENCE = 2 * Math.PI * RADIUS

  const progress = value / 100
  const dashoffset = CIRCUMFERENCE * (1 - progress)

  return new Handlebars.SafeString(progressCircleHelper(property, classList, value, dashoffset))
})

Handlebars.registerHelper('countdown', (property, argClassList, argData) => {
  // Make sure the helper uses the correct data
  // refactor - i think it's weird the helper works this way - visit this later.
  const { data, classList } = constructData(argData, argClassList)

  if (!hasData(data)) {
    return ''
  }

  return new Handlebars.SafeString(countdownHelper(property, classList, data))
})

Handlebars.registerHelper('dynamicVideo', (property, argClassList, argData) => {
  // Make sure the helper uses the correct data
  // refactor - i think it's weird the helper works this way - visit this later.
  const { data, classList } = constructData(argData, argClassList)

  if (!hasData(data)) {
    return ''
  }

  const binding = data.data.root
  const getProp = get(binding, property)
  const prop = { ...getProp }
  // prop.autoPlay = prop.autoPlay && prop.autoPlay ? 1 : 0
  // prop.loop = prop.loop && prop.loop ? 1 : 0

  if (typeof prop === 'string' || !prop || !prop.type || !prop.videoId || !toggleIsOn(binding, property)) {
    return ''
  }

  if (prop.type === 'facebook') {
    return new Handlebars.SafeString(facebookVideoHelper(prop, property, classList))
  } else {
    return new Handlebars.SafeString(youtubeHelper(prop, property, classList))
  }
})

Handlebars.registerHelper('video', (property, classList, data) => {
  if (!property || property === 'video') {
    return Handlebars.helpers.dynamicVideo(property, classList, data)
  }

  const src = data.url
  const size = data.size

  // Give videos a default ratio of 16:9
  let ratio = (16 / 9) * 100
  if (size && size.height && size.width) {
    ratio = (size.height / size.width) * 100
  }

  return new Handlebars.SafeString(videoHelper(src, ratio))
})

const createImageHtml = (property, nodeName, argClassList, argData, theType) => {
  // Make sure the helper uses the correct data
  // refactor - i think it's weird the helper works this way - visit this later.
  const { data, classList } = constructData(argData, argClassList)

  if (!hasData(data)) {
    return ''
  }

  const binding = data.data.root
  const prop = get(binding, property)

  if (!prop || typeof prop === 'string' || !toggleIsOn(binding, property)) {
    return ''
  }

  if (binding.imageMask) {
    return new Handlebars.SafeString(imageMaskHelper(prop, property, nodeName, classList, data, binding._controlType, theType))
  }
  return new Handlebars.SafeString(imageHelper(prop, property, nodeName, classList, data, binding._controlType, theType))
}

const createIconHtml = (property, sectionType, argClassList, argData) => {
  // Make sure the helper uses the correct data
  // refactor - i think it's weird the helper works this way - visit this later.
  const { data, classList } = constructData(argData, argClassList)

  if (!hasData(data)) {
    return ''
  }

  const binding = data.data.root
  const prop = get(binding, property)

  if (!toggleIsOn(binding, property) || !prop) {
    return ''
  }

  return new Handlebars.SafeString(iconHelper(property, sectionType, classList, prop))
}

Handlebars.registerHelper('image', (property, nodeName, argClassList, argData) => createImageHtml(property, nodeName, argClassList, argData, 'image'))
Handlebars.registerHelper('icon', createIconHtml)
Handlebars.registerHelper('logo', (property, nodeName, argClassList, argData) => createImageHtml(property, nodeName, argClassList, argData, 'logo'))
Handlebars.registerHelper('readonlyImage', (property, nodeName, argClassList, argData) =>
  createImageHtml(property, nodeName, argClassList, argData, 'readonly-image')
)

Handlebars.registerHelper('contact', (config, classList, data) => {
  if (config && data && data.data && data.data.root) {
    const fieldsList = data.data.root
    const requestedFields = config.split(',')

    return new Handlebars.SafeString(contactHelpers(fieldsList, requestedFields, classList, data))
  }
})

const createLinkOrButton = (property, argClassList, argData, controlType = 'link') => {
  // Make sure the helper uses the correct data
  // refactor - i think it's weird the helper works this way - visit this later.
  const { data, classList } = constructData(argData, argClassList)

  const binding = data.data.root
  const prop = get(binding, property)

  if (!toggleIsOn(binding, property) || !prop) {
    return ''
  }

  if (controlType === 'form-button') {
    return new Handlebars.SafeString(formButtonHelper(prop, classList, property, controlType, 0))
  } else {
    return new Handlebars.SafeString(linkButtonHelper(prop, classList, property, controlType, 0, binding))
  }
}

Handlebars.registerHelper('link', createLinkOrButton)
registerHelperWithDataAsFirstArgument('callToAction', (data, property, classList) => {
  const binding = data.data.root
  let prop = get(binding, property)
  const linkHref = encodeToString(getHref(prop))

  if (!toggleIsOn(binding, property) || !prop) {
    return ''
  }

  prop = { ...prop }

  if (!linkHref && prop.linkType !== 'no-link' && prop.source) {
    prop.href = get(binding, prop.source)
    if (prop.source === 'global.phone') {
      prop.href = 'tel:' + prop.href
    }
    if (prop.source === 'global.email') {
      prop.href = 'mailto:' + prop.href
    }
  }

  classList = classList ? `button-lg ${classList} button-callToAction` : 'button-lg button-callToAction'
  return new Handlebars.SafeString(linkButtonHelper(prop, classList, property, 'button', 0, binding))
})
Handlebars.registerHelper('button', (property, classList, data) => {
  classList = classList ? `button ${classList}` : 'button'
  return createLinkOrButton(property, classList, data, 'button')
})

// BUG: @meint a formbutton has issues. contenteditable=true in a <button />  is not allowed in Firefox and IE
Handlebars.registerHelper('formButton', (property, classList, data) => {
  classList = classList ? `button ${classList}` : 'button'
  return createLinkOrButton(property, classList, data, 'form-button')
})

Handlebars.registerHelper('buttons', (property, classList, data, controlType = 'button') => {
  const binding = data.data.root
  const props = get(binding, property)

  if (!toggleIsOn(binding, property)) {
    return ''
  }

  if (!props) {
    return
  }

  let templateString = ''
  props.forEach((prop, index) => {
    templateString += linkButtonHelper(prop, classList, property, controlType, index, binding)
  })

  return new Handlebars.SafeString(templateString)
})

const doPropertiesExist = (propertyString, rootObject) => {
  const value = propertyString.split('.').reduce((o, i) => {
    if (o) {
      return o[i]
    }
  }, rootObject)
  return value !== undefined
}

Handlebars.registerHelper('hasProperty', (prop, value) => {
  const exists = doPropertiesExist(prop, value.data.root)
  if (!exists) {
    return ''
  }
  return value.fn(this)
})

Handlebars.registerHelper('doesNotHaveProperty', (prop, value) => {
  const exists = doPropertiesExist(prop, value.data.root)
  if (exists) {
    return ''
  }
  return value.fn(this)
})

Handlebars.registerHelper('social', (type, property, data) => {
  // Use this as a value when account isn't an object
  if (!data) {
    return ''
  }
  const account = data.value || data
  let provider = socialProviders[type]
  if (!provider) {
    provider = socialProviders.default
  }

  if (property === 'name') {
    return provider.name
  }

  const value = provider[property] || null
  if (property === 'link') {
    if (!value) {
      return account // The account link provided is probably a URL which does not need additional formatting.
    }
    return value.replace('{account}', account)
  }
  return new Handlebars.SafeString(socialHelper(provider))
})

Handlebars.registerHelper('facebookMedia', (property, nodeName, classList, item) => {
  const binding = item.data.root
  const data = (binding.attachments && binding.attachments.data[0]) || {}
  if (binding.media) {
    const type = binding.type
    const attachment = binding.attachments && binding.attachments.data[0]

    if (type === 'video') {
      attachment.size = data.media.image
      let type = attachment.type
      if (type === 'video_share_youtube') {
        type = 'video'
      }
      if (attachment.type === 'video_inline' || attachment.type === 'video_direct_response') {
        attachment.url = `https://www.facebook.com/plugins/video.php?href=${attachment.url}&show_text=0`
        type = 'facebookVideo'
      } else {
        type = 'videoEmbed'
      }
      attachment.useThumbnail = true
      return Handlebars.helpers.image('media', nodeName, classList, item)
    } else if (binding.media && binding.media.value) {
      return Handlebars.helpers.image('media', nodeName, classList, item)
    }
    return Handlebars.helpers.image(property, nodeName, classList, item)

    // offer
  } else if (data.image) {
    return Handlebars.helpers.image(property, nodeName, classList, item)
  }
  return null
})

Handlebars.registerHelper('localize', function (translationPath) {
  const value = GlobalProperties.localize(translationPath)
  return new Handlebars.SafeString(value)
})

Handlebars.registerHelper('compare', function (lvalue, operator, rvalue, options) {
  if (arguments.length < 3) {
    throw new Error("Handlerbars Helper 'compare' needs 2 parameters")
  }

  if (options === undefined) {
    options = rvalue
    rvalue = operator
    operator = '==='
  }

  const operators = {
    '==': function (l, r) {
      return l == r // eslint-disable-line
    },
    '===': function (l, r) {
      return l === r
    },
    '!=': function (l, r) {
      return l != r // eslint-disable-line
    },
    '!==': function (l, r) {
      return l !== r
    },
    '<': function (l, r) {
      return l < r
    },
    '>': function (l, r) {
      return l > r
    },
    '<=': function (l, r) {
      return l <= r
    },
    '>=': function (l, r) {
      return l >= r
    },
    typeof: function (l, r) {
      return typeof l === r // eslint-disable-line
    }
  }

  if (!operators[operator]) {
    throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator)
  }

  const result = operators[operator](lvalue, rvalue)

  if (result && typeof options.fn === 'function') {
    return options.fn(this)
  } else if (typeof options.inverse === 'function') {
    return options.inverse(this)
  }
})

Handlebars.registerHelper('debug', function (optionalValue) {
  console.log('Current Context')
  console.log('====================')
  console.log(this)

  if (optionalValue) {
    console.log('Value')
    console.log('====================')
    console.log(optionalValue)
  }
})

Handlebars.registerHelper('copyright', function (value) {
  const name = get(value, 'data.root.global.companyName') || ''
  const year = new Date().getFullYear()
  return new Handlebars.SafeString(`<span data-type="copyright">© ${year} ${name}</span>`)
})

Handlebars.registerHelper('isNotInPreview', function (opts) {
  const isPreview = typeof window !== 'undefined' && GlobalProperties.isRuntime && document.body.querySelector('.kv-site.kv-main.kv-edit-mode')
  if (!isPreview) {
    return new Handlebars.SafeString('' + opts.fn(this) + '')
  }
})

registerHelperWithDataAsFirstArgument('loader', data => {
  // const binding = data.data.root
  return new Handlebars.SafeString(LoaderTemplate())
})
