import BREAKPOINTS from '@eig-builder/core-utils/hooks/useResponsiveQuery'

export const handleMobileSelectionManagerUIControls = controlsShouldBeVisible => {
  const kvPage = document.querySelector('.kv-page')

  controlsShouldBeVisible
    ? kvPage.classList.remove('kv-focused')
    : kvPage.classList.add('kv-focused')
}

export const isMobileMode = () => window.innerWidth <= BREAKPOINTS.TABLET
