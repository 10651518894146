/* eslint-disable */
var objectFitPolyfiller = function(node) {
  var objectFit = window.getComputedStyle(node).objectFit || node.localName === 'video' ? 'cover' : null // Make sure this works on IE as well..

  if (!objectFit || !/^(contain|cover|fill)$/.test(objectFit)) {
    return
  }

  // Prepare container styles
  var outerWidth = node.clientWidth
  var outerHeight = node.clientHeight
  var outerRatio = outerWidth / outerHeight

  var nodeStyle = node.style
  var computedCSS = window.getComputedStyle(node)

  var addEventListener = node.addEventListener || node.attachEvent
  var removeEventListener = node.removeEventListener || node.detachEvent
  var on = node.addEventListener ? '' : 'on'
  var type = 'loadedmetadata'

  addEventListener.call(node, on + type, onload)

  if (node.complete) {
    setTimeout(() => {
      onload()
    }, 500)
  }

  function onload() {
    removeEventListener.call(node, on + type, onload)

    // prepare container styles.
    var imgCSS = {
      boxSizing: 'content-box',
      display: 'inline-block',
      overflow: 'hidden'
    }

    'backgroundColor backgroundImage borderColor borderStyle borderWidth bottom fontSize lineHeight height left opacity margin position right top visibility width'.replace(
      /\w+/g,
      function(key) {
        imgCSS[key] = computedCSS[key]
      }
    )

    // Prepare image styles.
    nodeStyle.border = nodeStyle.margin = nodeStyle.padding = 0
    nodeStyle.display = 'block'
    nodeStyle.height = nodeStyle.width = 'auto'
    nodeStyle.opacity = 1

    var innerWidth = node.videoWidth || node.width
    var innerHeight = node.videoHeight || node.height
    var innerRatio = innerWidth / innerHeight

    // style container
    var imgx = document.createElement('object-fit')

    imgx.appendChild(node.parentNode.replaceChild(imgx, node))

    for (let key in imgCSS) {
      if (imgx.style) {
        imgx.style[key] = imgCSS[key]
      }
    }

    // Style image
    let newSize

    if (objectFit === 'fill') {
      nodeStyle['-ms-transform-origin'] = '0% 0%'
      nodeStyle['-ms-transform'] = 'scale(' + outerWidth / innerWidth + ',' + outerHeight / innerHeight + ')'
    } else if (innerRatio < outerRatio ? objectFit === 'contain' : objectFit === 'cover') {
      newSize = outerHeight * innerRatio

      nodeStyle.width = Math.round(newSize) + 'px'
      nodeStyle.height = outerHeight + 'px'
      nodeStyle.marginLeft = Math.round((outerWidth - newSize) / 2) + 'px'
    } else {
      newSize = outerWidth / innerRatio

      nodeStyle.width = outerWidth + 'px'
      nodeStyle.height = Math.round(newSize) + 'px'
      nodeStyle.marginTop = Math.round((outerHeight - newSize) / 2) + 'px'
    }

    if (node.autoplay) {
      node.play()
    }
  }
}

var init = function() {
  if (document.querySelector('.kv-site')) {
    Array.from(document.querySelectorAll('video')).forEach(function(node) {
      objectFitPolyfiller(node)
    })
  } else {
    setTimeout(init)
  }
}

export default function() {
  if (/MSIE|Trident|Edge/.test(navigator.userAgent)) {
    init()
  }
}
