// EventEmitter with the ability to queue function calls for when listeners have not yet registered.
class EventEmitter {
  constructor () {
    this.events = {}
    this.queue = {}
  }

  on (namespace, fn) {
    if (!this.events[namespace]) {
      this.events[namespace] = []
    }
    this.events[namespace].push(fn)

    // Execute calls that have been emitted before a listener has been registered.
    if (this.queue.hasOwnProperty(namespace)) {
      this.queue[namespace].forEach(args => fn(...args))
    }
  }

  emit () {
    const args = Array.from(arguments)
    const namespace = args.shift()

    if (this.events.hasOwnProperty(namespace)) {
      this.events[namespace].forEach(fn => fn(...args))
      return
    }

    // If there are no listeners, we queue the event call for execution when a listener does register.
    if (!this.queue.hasOwnProperty(namespace)) {
      this.queue[namespace] = []
    }
    this.queue[namespace].push(args)
  }
}

export default EventEmitter
