import BaseController from './base-controller'

import get from 'lodash/get'
import every from 'lodash/every'

import LayoutHelper from 'editor/helpers/layout-helper'

import { getCssClass } from 'core/editor/helpers/style-loader'
import { isMobileMode } from 'core/helpers/mobile'

///////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////

class SectionController extends BaseController {
  constructor (parentController, model, stylesheetCollector, overrideStatus, injectFeatureInstances) {
    super(parentController, model)
    if (overrideStatus) {
      this.setStatus(overrideStatus)
    }
    const imports = this.imports
    this.control = new imports.SectionControl(this, model)
    this.backgroundControl = this.getControlOfType('background')
    this.controlControl = this.getControlOfType('content')

    if (imports.ColorManager) {
      this.colorManager = new imports.ColorManager(
        this,
        this.getSiteController(),
        this.getSubController('background'),
        stylesheetCollector
      )
    }

    if (injectFeatureInstances) {
      this.injectFeatureInstances = injectFeatureInstances
    }
  }

  getLayoutModel () {
    return this.model.layout
  }

  getId () {
    return this.model.id
  }

  getLayoutObj = (propName, fileName) => {
    return LayoutHelper.getLayoutObj(this.getLayoutModel(), propName, fileName)
  }

  getLayout = (propName, fileName) => {
    return LayoutHelper.getLayout(this.getLayoutModel(), propName, fileName)
  }

  isOnMainPage () {
    const siteController = this.getSiteController()
    const pageModel = this.getPageController().model
    return siteController.getMainPage() === pageModel
  }

  // is header but not on the main page and has sub.html file
  isSubHeader () {
    if (!this.isOnMainPage()) {
      if (LayoutHelper.hasLayoutFile(this.getLayoutModel(), 'section', 'sub')) {
        return true
      }
    }
  }

  getSectionLayout = () => {
    let fileName = this.getLayoutModel().sectionAlign

    if (this.isSubHeader()) {
      fileName = 'sub'
    }
    return this.getLayout('section', fileName)
  }

  getListItemLayout = fileName => this.getLayout('listItem', fileName)

  getSectionLayoutObj = () => this.getLayoutObj('section', this.getLayoutModel().sectionAlign)

  getListItemLayoutObj = fileName => this.getLayoutObj('listItem', fileName)

  getNavigationLayout = () => this.getLayout('section', 'navigation')

  isForPreview = () => {
    return this.model._isForPreview
  }

  innerGetValue (model) {
    let classes = this.getSubController('background').getCssId()

    const result = {}
    const layoutProp = model.layout
    for (const key in layoutProp) {
      classes += ` ${getCssClass(layoutProp[key])}`
    }

    if (this.imports.isPublisher && model.binding.fullPage && this.isOnMainPage()) {
      classes += ' kv-full-page'
    }

    if (model.binding.noanimation) {
      classes += ' no-animation'
    }
    result.cssClass = classes
    result.id = model.id
    return result
  }

  // is called from the section render because we pass this section controller as model for the control
  getValue = model => {
    if (this.editable()) {
      const value = this.innerGetValue(model)
      value.isMobileMode = isMobileMode()
      return value
    }
    return this.innerGetValue(model)
  }

//////////////////
////////////////////////////
/////////////////////////////////////////
///

///////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////
///

///////////////////////////////////
//////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////
//////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////

///////////////////////////////////
/////////////////////////////////////////////////////////////////////////
//////////////////////////////////////
/////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////
//////////////////////////////////////////
/////
///
///////////
}
export default SectionController
