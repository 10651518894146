const defaultList = {
  cta: 'fa-mouse-pointer',
  faq: 'fa-question',
  map: 'fa-map-marker',
  address: 'fa-map-marker',
  openHours: 'fa-clock-o',
  hours: 'fa-clock-o',
  people: 'fa-users',
  reviews: 'fa-newspaper-o',
  services: 'fa-globe',
  testimonals: 'fa-quote-right',
  subscribe: 'fa-tag'
}

// This helps indicate visually that the content of the section is not valid.
const buildTempFallback = (property, sectionType) => {
  const value = property && property.value

  if (!value || !value.includes('fa-') || value.includes('http')) {
    if (defaultList[sectionType]) {
      return defaultList[sectionType]
    }

    return 'fa-exclamation-triangle'
  }

  return value
}

const iconHelper = (property, sectionType, classList, data) => {
  const value = buildTempFallback(data, sectionType)

  return `
    <div class="${classList}" data-type="icon" data-property="${property}">
      <i class="fa ${value}"> </i>
    </div>
  `
}

export default iconHelper
