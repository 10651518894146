class PaginationSDK {
  constructor (config) {
    this.dataSource = config.url
    this.amountPerPage = config.itemsPerPage
    this.amountPerFetch = config.itemsPerFetch
    this.offsetKey = config.offsetKey || 'offset'
    this.amountKey = config.amountKey || 'amount'
    this.totalKey = config.totalKey || 'total'
    this.callback = config.callback
    this.sortBy = config.sortBy || 'id'
    this.useCache = config.cache || false
    this.transformResponse = config.transformResponse || ((res, next) => next(res))
    this.extraQueryParams = config.extraQueryParams || ''

    this.total = -1
    this.fetchedUntil = -1
    this.currentIndex = config.currentIndex || 0
    this.maxIndex = this.currentIndex
    this.items = []

    if (this.useCache) {
      this.cacheKey = null
    }
  }

  updateCache (uid) {
    if (this.useCache) {
      this.cacheKey = uid
      let cache = PaginationSDK.LocalStorage.get(uid)
      if (cache !== null) {
        cache = JSON.parse(cache)
        this.items.forEach(a => {
          const index = cache.findIndex(b => b.id === a.id)
          if (index !== -1) {
            // Item with same id exists.
            cache[index] = a
          } else {
            // Item doesnt exist yet.
            cache.push(a)
          }
        })
      }
      PaginationSDK.LocalStorage.set(uid, JSON.stringify(cache || []))
    }
  }

  _fetchItems (offset) {
    return new Promise((resolve, reject) => {
      const url = `${this.dataSource}?${this.offsetKey}=${offset}&${this.amountKey}=${this.amountPerFetch}${this.extraQueryParams}`
      PaginationSDK.fetch(url)
        .then(res => res.json())
        .then(res => {
          this.total = res.hasOwnProperty(this.totalKey) ? res[this.totalKey] : res.length || -1
          this.maxIndex = this.total === -1 ? -1 : Math.ceil(this.total / this.amountPerPage)
          this.fetchedUntil = offset + this.amountPerFetch
          this.transformResponse(res, resolve)
        })
        .catch(reject)
    })
  }

  async render (forceFetch = false) {
    const items = await this.getItems(0, forceFetch)
    this.callback(items)
  }

  diffItems (newItems) {
    const items = [...this.items]
    newItems.forEach(newItem => {
      const index = items.findIndex(oldItem => oldItem[this.sortBy] === newItem[this.sortBy])
      if (index !== -1) {
        items[index] = newItem
      } else {
        items.unshift(newItem)
      }
    })

    const result = items.sort((a, b) => {
      if (Number(a[this.sortBy]) > Number(b[this.sortBy])) {
        return -1
      }
      if (Number(a[this.sortBy]) < Number(b[this.sortBy])) {
        return 1
      }
      return 0
    })

    return result
  }

  async getItems (pageIndex, forceFetch = false) {
    const startOffset = pageIndex * this.amountPerPage
    const endOffset = startOffset + this.amountPerPage

    this.currentIndex = pageIndex

    let cachedPosts = []
    if (this.useCache) {
      cachedPosts = PaginationSDK.LocalStorage.get(this.cacheKey)
      if (cachedPosts) {
        const cachedResult = JSON.parse(cachedPosts)
          .filter(item => item)
          .slice(startOffset, startOffset + this.amountPerPage)
        if (cachedResult.length > 0) {
          this.callback(cachedResult)
        }
      }
    }

    if (endOffset > this.fetchedUntil || forceFetch) {
      const newItems = await this._fetchItems(startOffset)
      this.items = this.diffItems(newItems)
    }

    const result = this.items.filter(item => item).slice(startOffset, startOffset + this.amountPerPage)

    if (this.useCache) {
      this.changesAfterCache = false
      cachedPosts = JSON.parse(cachedPosts)
      if (cachedPosts) {
        if (
          result.some(a => {
            const b = cachedPosts.find(post => post.id === a.id)
            return JSON.stringify(a) !== JSON.stringify(b)
          })
        ) {
          this.changesAfterCache = true
        }
      }
    }

    return result
  }

  async next () {
    const newIndex = this.currentIndex + 1
    if (newIndex < this.maxIndex) {
      const items = await this.getItems(newIndex)

      if (!this.useCache) {
        this.callback(items)
      } else if (this.useCache && this.changesAfterCache) {
        this.callback(items)
      }
    }
  }

  async previous () {
    const newIndex = this.currentIndex - 1
    if (newIndex >= 0) {
      const items = await this.getItems(newIndex)
      if (!this.useCache) {
        this.callback(items)
      } else if (this.useCache && this.changesAfterCache) {
        this.callback(items)
      }
    }
  }

  async goto (pageIndex) {
    if (pageIndex === this.currentIndex) {
      return
    }
    const items = await this.getItems(pageIndex)
    if (!this.useCache) {
      this.callback(items)
    } else if (this.useCache && this.changesAfterCache) {
      this.callback(items)
    }
  }
}

export default PaginationSDK
