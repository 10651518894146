import get from 'lodash/get'

const GlobalPrefix = 'global.'

export default function getFieldValue (sectionBinding, property, globalBinding) {
  // toggle functionality
  if (property.isToggle) {
    const fieldNames = property.matchingFields || [property.name]
    const toggle = getToggleObj(sectionBinding, property.toggleType)
    return fieldNames.some(propertyField => {
      let hasValue = true

      // Also check if the field really has any value, only if the property requires this.
      if (property.checkFieldValueExists) {
        let field = propertyField
        if (property.isGlobal) {
          field = field.substr(GlobalPrefix.length)
          hasValue = !!get(globalBinding, field)
        } else {
          hasValue = !!get(sectionBinding, field)
        }
      }

      return (!toggle || toggle[propertyField] !== false) && hasValue
    })
  }

  const value = get(sectionBinding, property.name)

  // singleplatform location hacks
  if (property.category === 'location' && (property.name === 'location' || property.name === 'globalLocationId')) {
    if (!globalBinding.locations || !globalBinding.locations[value]) {
      // Fall back to default
      return '1'
    }
  }

  return value
}

export function getToggleObj (data, toggleType, createIfNotExists) {
  if (createIfNotExists && !data._toggle) {
    data._toggle = {}
  }

  if (toggleType === 'listElements') {
    // category listElement uses _list-item as sub obj for toggle state
    if (createIfNotExists && !data._toggle['_list-item']) {
      data._toggle['_list-item'] = {}
    }
    return get(data, '_toggle._list-item')
  }
  return data._toggle
}
