// Events SDK has been built using window scope because features are not sharing editor-feature-sdk.js instances
// which means that the Events class will be constructed for every EditorFeatureSdk instance thus not being able
// to serve its purpose which is to provide a global event system across all active features.

class Events {
  constructor () {
    if (!window.hasOwnProperty('_feature_events')) {
      window._feature_events = {}
    }
  }

  on (namespace, callback) {
    if (window._feature_events.hasOwnProperty(namespace)) {
      window._feature_events[namespace].push(callback)
      return
    }
    window._feature_events[namespace] = [callback]
  }

  off (namespace, callback) {
    if (window._feature_events.hasOwnProperty(namespace)) {
      const callbacks = window._feature_events[namespace]
      const foundIndex = callbacks.findIndex(fn => fn === callback)
      if (foundIndex !== -1) {
        callbacks.splice(foundIndex, 1)
      }
    }
  }

  emit () {
    const args = Array.from(arguments)
    const namespace = args.shift()
    if (window._feature_events.hasOwnProperty(namespace)) {
      const callbacks = window._feature_events[namespace]
      callbacks.forEach(fn => {
        fn(...args)
      })
    }
  }
}

export default Events
