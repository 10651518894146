import ContentController from 'editor/controllers/content-controller'

class NavigationController extends ContentController {
  constructor (parentController, model) {
    super(parentController, model)
    this.control = new this.imports.NavigationControl(this, model)
  }

  registerView (view, model, addOrDeleteBool) {
    // do nothing for now, this view shares the model with the content-view of the header. When the content is rerendered the navigation is a child, this is also rerendered
  }
}
export default NavigationController
