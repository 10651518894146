import { getHref } from '../utils'
import { initStyles, anchorStyle, getImageObject, buildOuterAttributes, buildCropStyle, handleRenderWithCallback } from './build-image-attributes'

import uuid from 'uuid/v4'

const buildResponsiveClipPath = (clipPath, width, height) => {
  const test = document.createElement('div')
  test.innerHTML = clipPath

  const path = test.querySelector('path')
  const pathToDraw = path.getAttribute('d')

  let count = -1
  // Get each individual path point and convert it to a responsive point between 0 and 1.
  const responsivePath = pathToDraw.replace(/(\d+(\.\d+)?)/g, function () {
    count++
    // In SVG, each point exists of a x and a y attribute. To convert this correctly while preventing aspect ratio, we ping-pong the returns with modulo.
    if (count % 2) {
      return arguments[0] / height
    }
    return arguments[0] / width
  })
  return responsivePath
}

export const getClipPath = (binding) => {
  const imageMaskParent = document.createElement('div')
  imageMaskParent.innerHTML = binding.imageMask

  const svg = imageMaskParent.querySelector('svg')

  const width = svg.getAttribute('width')
  const height = svg.getAttribute('height')
  return buildResponsiveClipPath(svg.querySelector('g').innerHTML, width, height)
}

const imageMaskHelper = (prop, property, nodeName, classList, data, controlType, dataType = 'image') => {
  const binding = data?.data?.root
  const { link, width, height, crop } = prop
  const linkHref = getHref(link)

  let outerAttributes = buildOuterAttributes(dataType, property)

  let anchorTag = ''
  if (linkHref) {
    anchorTag = `<a href="${linkHref}" data-href="${linkHref}" style="${anchorStyle}"
        ${link.shouldOpenInTab ? 'target="_blank"' : ''}
        ></a>`
  }

  if (binding.hover) {
    outerAttributes += ` class="${binding.hover}"`
  }

  const { imgValue, imgValueRetina, imgValueLowRes } = getImageObject(dataType, prop, controlType)
  if (!imgValue) {
    return ''
  }
  let styles = initStyles(width, height)
  let nodeStyles = ''

  if (binding.dropShadow) {
    nodeStyles += 'filter: drop-shadow(0 8px 12px rgba(0, 0, 0, 0.4));'
  }
  styles = buildCropStyle(crop, styles)

  return handleRenderWithCallback(binding, prop, classList, nodeName, outerAttributes, anchorTag, styles, controlType, {
    imgValue,
    imgValueLowRes,
    imgValueRetina
  }, (innerAttributes, styles) => {
    const id = binding.imageMaskSeed || uuid()

    const nodeStyleBorderMask = binding.borderWidth ? `-webkit-clip-path: url(#${id}); clip-path: url(#${id});` : null
    const nodeStyleBorderClasses = binding.borderWidth ? (binding.borderColor ? binding.borderColor.class : 'custom-border-cl3') : null

    const parentNode = (children) => {
      return binding.borderWidth
        ? `
          <${nodeName}
            ${outerAttributes}
            style="${nodeStyles} width: 100%; height: 100%;"
            data-setting="shadow"
          >
            <div class="${nodeStyleBorderClasses}" style="width: 100%; height: 100%; ${nodeStyleBorderMask}" data-setting="border">
              ${children}
            </div>
          </${nodeName}>
        ` : `

        <${nodeName}
          ${outerAttributes}
          style="${nodeStyles} width: 100%; height: 100%;"
          data-setting="shadow"
        >
          ${children}
        </${nodeName}>
        `
    }

    return parentNode(`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        style="position: absolute; top: 0; left: 0;"
      >
        <clipPath id="${id}" clipPathUnits="objectBoundingBox">
          <path stroke="none" stroke-width="0" fill="transparent" d="">
        </clipPath>
      </svg>
      <div data-setting="path" style="-webkit-clip-path: url(#${id}); clip-path: url(#${id}); width: 100%; height: 100%; transform: scale(${binding.borderWidth ? ((100 - (binding.borderWidth * 0.5)) / 100) : 1})">
        <${nodeName}
          ${innerAttributes}
          style="${styles} width: 100%; height: 100%; padding-bottom: 0;"
        >
          ${anchorTag}
        </${nodeName}>
      </div>
    `)
  })
}
export default imageMaskHelper
