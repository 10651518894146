import DefaultGlobalBinding from './default-global-binding'

import mergeWith from 'lodash/mergeWith'
import isEmpty from 'lodash/isEmpty'

const locationFields = ['openingHours', 'address', 'companyName', 'phone', 'email', 'singleplatformAccount']
const locationFeatures = ['googlemaps']
const locationSources = ['singleplatformMenu']

class GlobalBindingHelper {
  static customizer (srcValue, defaultGlobalBinding) {
    // Check for empty objects and return the default global binding of object/array is empty
    if (isEmpty(srcValue)) {
      return defaultGlobalBinding
    } else if (srcValue) {
      return srcValue
    }
  }

  static getLocationFields () {
    return locationFields
  }

  static getLocationFeatures () {
    return locationFeatures
  }

  static getLocationSources () {
    return locationSources
  }

  static mergeGlobalBindingWithDefaultValues (globalBinding) {
    const result = mergeWith(globalBinding, DefaultGlobalBinding, this.customizer)
    return result
  }

  static mergeOpeningHoursWithDefaultValues (openingHours) {
    const defaultHours = DefaultGlobalBinding.openingHours

    const result = {}
    Object.keys(defaultHours).map(key => {
      result[key] = openingHours[key] || defaultHours[key]
    })
    return result
  }
}

export default GlobalBindingHelper
