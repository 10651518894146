const paginationHelper = data => {
  const pages = data.showPages
    ? Array.from(new Array(data.pages), (x, i) => i)
      .map(i => {
        return `<button ${data.activeIndex === i && 'class="pagination-active"'} data-pagination-id="${i}">${i +
            1}</button>`
      })
      .join('')
    : ''

  const pagesContent = data.showPages
    ? `<div class="pagination-pages">
      ${pages}
    </div>`
    : ''

  return `<div class="pagination-container">
    <button ${data.previousAvailable && 'class="pagination-visible"'} data-pagination-previous>&lt;</button>
    ${pagesContent}
    <button ${data.nextAvailable && 'class="pagination-visible"'} data-pagination-next>&gt;</button>
  </div>`
}

export default paginationHelper
