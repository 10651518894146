import CoreListController from 'core/editor/controllers/list-controller'

class ListController extends CoreListController {
  getColumnClassNames (columns) {
    if (columns === undefined) {
      return null
    }
    if (columns >= 6) {
      return 'col-4 col-sm-3 col-md-2'
    }

    if (columns >= 4) {
      return 'col-6 col-sm-4 col-md-3'
    }

    if (columns >= 3) {
      return 'col-6 col-md-4'
    }

    if (columns >= 2) {
      return 'col-12 col-sm-6'
    }

    return 'col-12'
  }

  getValue = model => {
    return this.internalGetValue(model)
  }
}
export default ListController
