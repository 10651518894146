import Fetch from 'core/helpers/data/fetch-wrapper'
import { storageUrl } from 'api-config'
import AuthUploadHelper from '@eig-builder/module-image-selector/helpers/auth-upload-helper'
import { api } from '@eig-builder/core-utils/helpers/url-helper'
import uniq from 'lodash/uniq'
import { isLoggedInAccount } from '../account/account-type-helper'
import Store from '@eig-builder/core-utils/store'

const recursiveIterateObjects = (obj, callback, depth = 0) => {
  if (depth > 100) {
    throw new Error('recursion in model')
  }

  if (obj && typeof obj === 'object') {
    if (Array.isArray(obj)) {
      obj.forEach(o => recursiveIterateObjects(o, callback, depth + 1))
    } else {
      try {
        callback(obj)
      } catch (ex) {
        window.rg4js('send', { error: ex })
      }
      for (const key in obj) {
        recursiveIterateObjects(obj[key], callback, depth + 1)
      }
    }
  }
}

const preProcess = (images, inputObject) => {
  let handled = false
  recursiveIterateObjects(inputObject, obj => {
    if (typeof obj._replace_img === 'string' && obj[obj._replace_img] === 'pending') {
      obj[obj._replace_img] = 'call'
      const original = obj[`temp_${obj._replace_img}`]
      images.push({
        obj,
        original
      })
      handled = true
    }
  })
  return handled
}

export const test = inputObject => {
  recursiveIterateObjects(inputObject, obj => {
    if (obj._replace_img) {
      console.dir(obj)
      if (obj[obj._replace_img] === 'pending') {
        console.error('PENDING')
      }
    }
  })
}

const _process = convertImages => {
  if (!convertImages.length) {
    return Promise.resolve()
  }

  const uploadUrls = uniq(convertImages.map(i => i.original))

  AuthUploadHelper.tokenUrl = api('')
  AuthUploadHelper.authToken = Store().getState().auth.xsrfToken
  return AuthUploadHelper.checkAuth().then(token => {
    return Fetch.postAsJson(
      storageUrl('images/generate-image-urls'),
      {
        urls: uploadUrls,
        is_temporary: true
      },
      {
        fetchOptions: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      }
    ).then(response => {
      response.urls.forEach((newUrl, index) => {
        const oldUrl = uploadUrls[index]
        convertImages
          .filter(i => i.original === oldUrl)
          .forEach(holder => {
            const obj = holder.obj
            obj[obj._replace_img] = newUrl // store the image in the field
          })
      })
    })
  })
}

const processAllPostAccountCreate = async (editActions, flatTree) => {
  const convertImages = []
  const changes = {}
  for (const treeId in flatTree) {
    if (preProcess(convertImages, flatTree[treeId])) {
      changes[treeId] = { ...flatTree[treeId] }
    }
  }

  if (convertImages.length > 0) {
    await _process(convertImages)

    editActions.multiChange(changes)
    // editActions.commitChanges()
  }
}

const processAllTempImageUrls = inputObject => {
  if (!isLoggedInAccount()) {
    // postpone to when account is created
    return Promise.resolve()
  }

  const convertImages = []
  preProcess(convertImages, inputObject)

  return _process(convertImages)
}

const makeTempImageAndPrepareStoringImage = (model, propertyName) => {
  model._replace_img = propertyName
  model[`temp_${propertyName}`] = model[propertyName]
  model[propertyName] = 'pending'
}

export { recursiveIterateObjects, processAllPostAccountCreate, preProcess, _process, processAllTempImageUrls, makeTempImageAndPrepareStoringImage }
