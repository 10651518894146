import BaseControl from 'editor/controls/base-control'

////////////////
///////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////////////////////
/////////

class PageControl extends BaseControl {
  constructor (controller, Layout) {
    super(controller, () => Layout, controller.model, 'page')
    this.lazyImageSelector = ''
  }

  registerView (addOrDeleteBool) {
    // don't re-render when page model in self changes
    this.controller.registerView(this, this.model.sections, addOrDeleteBool)
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

////////////////////
//////////////////////////////////////////////////////
////////////////////////////////////
//////////////////////////////////////////////
///////
//////
/////////////
  }

//////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////
//////////////////////////
////////////////////////////
/////

/////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////

//////////////////////////////

///////////////////////////
/////////////////////////////////////////////////

///////////////
///

//////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
/////////////////////////////////////////
//////////////////////////
///
///////////
}
export default PageControl
