import BaseController from './base-controller'

import Dictionary from 'core/editor/helpers/dictionary'

class PageController extends BaseController {
  constructor (parentController, model, createSectionDelegate) {
    super(parentController, model)

    this.control = new this.imports.PageControl(this)
    this.controlDictionary = new Dictionary(section => {
      if (createSectionDelegate) {
        return createSectionDelegate(this, section)
      } else {
        const sectionController = new this.imports.SectionController(this, section)
        return sectionController.control
      }
    })
  }

  getChildControls () {
    return this.controlDictionary.mapWithCache(this.getAllSections())
  }

  getSectionControllers () {
    return this.getChildControls().map(control => control.controller)
  }

  getAllSections () {
    return this.getSiteController().getAllSectionsForPage(this.model)
  }

  updateSectionKVControls () {
    this.getChildControls().forEach(control => control.updateKVControls())
  }

  forEachContentControls (callback) {
    this.getSectionControllers().forEach(section => {
      const contentControl = section.getControlOfType('content')
      callback(contentControl)
    })
  }
}
export default PageController
