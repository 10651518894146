import cloneDeep from 'lodash/cloneDeep'
import { PickColor } from 'core/editor/managers/color-manager'
import ColorFunctions from 'core/editor/helpers/color-functions'
import sortBy from 'lodash/sortBy'
import isNil from 'lodash/isNil'
import FontHelper from 'core/helpers/data/fonts/font-helper'
import shuffle from 'lodash/shuffle'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import flatten from 'lodash/flatten'

const colorSchemes = [
  {
    // Landana Green fallback color for if templates don't have color pairs
    background: [30, 30, 30],
    text: [255, 255, 255],
    accent: [[147, 159, 117], [71, 64, 86]]
  }
]

const hasBackgroundMedia = model => {
  return (model.opacity === undefined || model.opacity > 0) && (model.img || model.image || model.video)
}

const hasField = (name, sectionBinding) => {
  return sectionBinding[name] && (!sectionBinding._toggle || sectionBinding._toggle[name])
}

// return 1 of higher is background visual, 0 is a plain white boring section
const sectionVisualIntensityScore = (layoutsObject, section) => {
  const binding = section.binding
  let score = 0
  const layout = layoutsObject.getById(section.layout.section.id)
  if (layout.fields.indexOf('image') >= 0 && hasField('image', binding)) {
    score += 0.5
  }
  const back = binding.background
  if (hasBackgroundMedia(back)) {
    score += 1
  } else {
    if (back.patternIndex) {
      score += 0.5
    }
    if (back.colorIndex === 1) {
      score += 0.25
    } else if (back.colorIndex > 1) {
      score += 0.5
    }
  }

  return score
}

const COLOR_ACCENT1 = 3
const styleBackground = (section, demoDataObj, canAddBackgroundImage) => {
  canAddBackgroundImage = canAddBackgroundImage && demoDataObj

  let type = ~~(Math.random() * 6)
  if (!canAddBackgroundImage) {
    type += 2
  }

  const backBinding = {
    colorIndex: 0
  }

  // random apply styling
  if (type <= 1) {
    backBinding.img = demoDataObj.pickImage(true)
    if (type === 0) {
      backBinding.opacity = 50
      backBinding.color = [80, 80, 80]
    } else {
      backBinding.colorIndex = COLOR_ACCENT1
      backBinding.opacity = 30
    }
  } else if (type <= 3) {
    backBinding.patternIndex = ~~(Math.random() * 8)
    if (type === 3) {
      backBinding.colorIndex = COLOR_ACCENT1
    }
  } else if (type <= 5) {
    backBinding.colorIndex = COLOR_ACCENT1
  }

  section.binding.background = backBinding
}

const styleSection = (layoutsObject, demoDataObj, sections, index) => {
  const adjacentScore = sectionVisualIntensityScore(layoutsObject, sections[index - 1]) + sectionVisualIntensityScore(layoutsObject, sections[index + 1])
  const section = sections[index]

  if (sectionVisualIntensityScore(layoutsObject, section) < 0.5 && adjacentScore < 1) {
    const canAddBackground = !section.layout.listItem // dont add background if we have list items, looks less nice
    styleBackground(section, demoDataObj, canAddBackground)
  }
}
const randomizeSectionsRec = (layoutsObject, demoDataObj, sections, prevSectionIndex, nextSectionIndex) => {
  if (nextSectionIndex - prevSectionIndex <= 1) {
    return
  }
  const middle = parseInt((nextSectionIndex + prevSectionIndex) * 0.5)

  // style middle
  styleSection(layoutsObject, demoDataObj, sections, middle)

  randomizeSectionsRec(layoutsObject, demoDataObj, sections, prevSectionIndex, middle)
  randomizeSectionsRec(layoutsObject, demoDataObj, sections, middle, nextSectionIndex)
}

const pickRandomItem = items => {
  return items[~~(Math.random() * items.length)]
}

class RandomizeSite {
  constructor (layoutsObject, demoDataObj, sanitizeSectionFunc, template) {
    this.layoutsObject = layoutsObject
    this.demoDataObj = demoDataObj

    // adjust the template to have random 6 colors per category
    if (template.colorSchemes) {
      // wtf is this shiiiiit: Well, it shuffles the colors and groups them by category and ensures max 6 matches per category
      this.possibleColors = flatten(map(groupBy(shuffle(template.colorSchemes), 'style'), array => array.slice(0, 6)))
      template.colorSchemes = this.possibleColors
    } else {
      this.possibleColors = colorSchemes
    }

    this.fontStyles = template.fontPairs
    this.sanitizeSection = sanitizeSectionFunc
  }

  forEachPage (site, callback) {
    site.pages.forEach(page => {
      const globalSections = site.globalSections
      const sections = [page.headerSection || globalSections.header, ...page.sections, globalSections.footer].filter(i => i)
      callback(site, sections, page.mainPage)
    })
  }

  randomizeColors (site) {
    // pick 40% of time minimal (Jerry)
    const pickMinimal = Math.random() < 0.4
    const colors = this.possibleColors.filter(i => (i.style !== 'minimal') ^ pickMinimal)

    const scheme = pickRandomItem(colors) || this.possibleColors[0]
    site.theme.colors = cloneDeep(scheme)
  }

  randomizeFonts (site) {
    if (this.fontStyles) {
      const fontStyle = pickRandomItem(this.fontStyles)

      const fontStyleObj = FontHelper.getFontStyles()[fontStyle]
      if (fontStyleObj) {
        const pair = pickRandomItem(fontStyleObj.fontPairs)
        site.theme.fonts = FontHelper.fontToEditorObj(pair, site.theme.fonts)
      }
    }
  }

  styleFooter (site, footerSection) {
    // find the darkest background color (so that the footer is always dark)
    let index = 0
    let nextColor
    const colorArray = []
    while ((nextColor = PickColor(site.theme.colors, index))) {
      if (index === 4) {
        // Only pick colors from your theme.
        break
      }
      colorArray.push({
        lightness: ColorFunctions.colorLightness(...nextColor),
        index: index++
      })
    }
    if (colorArray.length) {
      footerSection.binding.background = {
        colorIndex: sortBy(colorArray, i => i.lightness)[0].index
      }
    }
  }

  restlyeSections (site) {
    const hasColorsFromLogo = !isNil(site.theme.colors.is_from_logo)
    if (!hasColorsFromLogo) {
      this.randomizeColors(site)
    }
    this.randomizeFonts(site)
    this.randomizeLayouts(site)

    this.forEachPage(site, this.clearAllBackgrounds)

    this.styleFooter(site, site.globalSections.footer)

    let firstNoneMainHeader
    site.pages.forEach(page => {
      if (!page.mainPage) {
        if (!firstNoneMainHeader) {
          firstNoneMainHeader = page.headerSection
          if (!isNil(site.theme.colors.logo_background_color)) {
            firstNoneMainHeader.binding.background = { colorIndex: site.theme.colors.logo_background_color }
          } else if (Math.random() > 0.5) {
            // give none-mainpage header some color
            styleBackground(firstNoneMainHeader, this.demoDataObj, false)
          }
        } else {
          // copy header section styling from other header section
          page.headerSection.binding.background = { ...firstNoneMainHeader.binding.background }
        }
      }
    })

    // add some colors and images
    this.forEachPage(site, this.randomizeBackgrounds)

    // logo_background_color
  }

  randomizeBackgrounds = (site, sections, isMainPage) => {
    randomizeSectionsRec(this.layoutsObject, this.demoDataObj, sections, 0, sections.length - 1)
  }

  // remove backgrounds (all styling) except for layout that prefer a background image
  // also add image if the layout has a image option
  clearAllBackgrounds = (site, sections, isMainPage) => {
    sections.forEach((section, index) => {
      const backBinding = {
        colorIndex: 0
      }
      const binding = section.binding
      binding.background = backBinding

      const layout = this.layoutsObject.getById(section.layout.section.id)
      if (layout.fields.indexOf('image') >= 0) {
        if (binding._toggle && !binding._toggle.image) {
          binding._toggle.image = true
        }
        binding.image = this.demoDataObj.pickImage(false)
      }

      const isHeaderSection = index === 0
      const isHeaderMainPageSection = isHeaderSection && isMainPage
      if ((!isHeaderSection && layout.metadata.preferBackgroundImage) || isHeaderMainPageSection) {
        backBinding.img = this.demoDataObj.pickImage(true, isHeaderMainPageSection)
        backBinding.opacity = 50
        backBinding.color = [80, 80, 80]
        if (isHeaderMainPageSection && site.theme.colors.hasOwnProperty('logo_background_color')) {
          backBinding.colorIndex = site.theme.colors.logo_background_color
        }
      } else if (layout.metadata.preferAccentColor) {
        backBinding.colorIndex = COLOR_ACCENT1
      }
    })
  }

  randomizeLayouts = site => {
    this.forEachPage(site, this.randomizeSectionLayouts)

    // copy header layout setting
    const mainPage = site.pages.find(i => i.mainPage) || site.pages[0]
    site.pages.forEach(page => {
      page.headerSection.layout = mainPage.headerSection.layout
    })
  }

  randomizeSectionLayouts = (site, sections, isMainPage) => {
    sections.forEach(section => {
      let sectionLayout
      let listItemLayout

      let layouts = this.layoutsObject.getLayoutsForCategory(section.category)
      if (layouts.length === 0) {
        return true
      }

      if (section.layout.listItem) {
        layouts = layouts.filter(i => i.metadata.parents)
        listItemLayout = pickRandomItem(layouts)
        sectionLayout = pickRandomItem(this.layoutsObject.getParentLayouts(listItemLayout))
      } else {
        sectionLayout = pickRandomItem(
          layouts.filter(layout => {
            return !layout.metadata.parents
          })
        )
      }

      // if the layout has an image, move the background image to the image field, and clear the background
      /* const back = binding.background
      if (sectionLayout.fields.indexOf('image') >= 0) {
        if (back.img) {
          binding.image = back.img
          delete back.img
        }
      } else {
        if (binding.image && !back.img) {
          back.img = binding.image
        }
      } */

      if (sectionLayout) {
        section.layout.section.id = sectionLayout.id
      }
      if (listItemLayout) {
        section.layout.listItem.id = listItemLayout.id
      }

      this.sanitizeSection(section, sectionLayout, listItemLayout)
    })
  }
}

export default RandomizeSite
