import get from 'lodash/get'
import buildInputNode from './build-input-node'
import toggleIsOn from 'core/editor/helpers/handlebars/toggle-helper'

const findBinding = (classList, data) => {
  if (classList.data) {
    return classList.data.root
  } else {
    return data.data.root
  }
}

const getValue = (binding, property) => {
  return toggleIsOn(binding, property) ? get(binding, property) : ''
}

const priceHelper = (property, nodeName, classList, data) => {
  const binding = findBinding(classList, data)
  const value = getValue(binding, property)

  return value === '' ? value : buildInputNode(nodeName, `class="${classList}" data-type="price"`, value, property)
}

export default priceHelper
