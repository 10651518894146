import CoreSiteController from 'core/editor/controllers/site-controller'

import { getPageUrl } from 'core/helpers/page-url-helper'

import { createCompleteNavigationItems, getVirtualNavigationItems } from 'site/designer/helpers/navigation/navigation-helper'

class SiteController extends CoreSiteController {
  hasSectionBasedNavigation () {
    return this.model.pages.filter(p => p.showInNavigation).length <= 1
  }

  generateMenu () {
    const items = this.internalGenerateMenu((activePage, mainPageUri) => {
      const navItems = getVirtualNavigationItems(this.model.pages, createCompleteNavigationItems(this.model.pages, this.model.navigation))
      return navItems.map((navItem, index) => {
        const { page } = navItem
        let uri
        let showInNavigation = true
        let newTab = '_self'
        if (page) {
          uri = '/' + (mainPageUri === page.uriPath ? '' : page.uriPath)
          uri = getPageUrl(uri)
          showInNavigation = page.showInNavigation
        } else {
          uri = navItem.menuItem ? navItem.menuItem.uriPath : navItem.id
          if (navItem.menuItem) {
            showInNavigation = navItem.menuItem.showInNavigation
            newTab = navItem.menuItem.externalUrlNewTab ? '_blank' : '_self'
          }
        }

        return {
          title: navItem.title !== undefined ? navItem.title : (navItem.menuItem && navItem.menuItem.title),
          uriPath: uri,
          href: uri,
          index,
          active: activePage === page,
          newTab: newTab,
          showInNavigation: showInNavigation
        }
      })
    })
    return items.filter(item => {
      return item.showInNavigation !== false
    })
  }
}
export default SiteController
