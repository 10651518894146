/**
 * Static class for global properties accessable from handlebar helpers
 * the site model instance is set from the siteController
 */

// Core Helpers - Data
import fetch from '../data/fetch-wrapper'

class GlobalProperties {
  constructor () {
    this.internalLocalize = key => key
    this._loadedImages = {}
    this.useCloudflareCdn = false // hack to make use of imageproxy instead of direct unsplash images

    // do a call to also get the translations from the editor-templates in, this only need to be done on localhost
    if (process.env.NODE_ENV === 'development') {
      fetch
        .fetchAsJson(
          'https://translations.dev.appmachine.com/v1.0/translations?productName=appdesigner&groupName=Javascript&language=en-us'
        )
        .then(i => {
          this.devTranslations = i
        })
    }
  }

  // TODO: @dvandervlag This method does not belong here..
  getPageByIdOrPath (idOrUri) {
    if (!this.site && window._site) {
      this.site = window._site
    }
    idOrUri = String(idOrUri)
    const pageWithMatchingId = this.site.pages.find(i => String(i.id) === idOrUri)
    if (pageWithMatchingId) {
      return pageWithMatchingId
    }
    // Demo data links to certain pages with an uriPath
    const pageWithMatchingUriPath = this.site.pages.find(i => i.uriPath === idOrUri)
    if (pageWithMatchingUriPath) {
      return pageWithMatchingUriPath
    }
    return {}
  }

  registerLocalize (localize) {
    this.internalLocalize = localize
  }

  localize (key, ...args) {
/////////////////////
    if (window._featureSettings) {
      return (window._featureSettings.translations && window._featureSettings.translations[key]) || key
    }
/////////////
    let str = this.internalLocalize(key)

    if (process.env.NODE_ENV === 'development') {
      // only for localhost, add the translations from the edtiro templates
      if (str === key && this.devTranslations) {
        str = this.devTranslations[key]
        if (!str) {
          str = key
        }
      }
    }

    for (const i in args) {
      str = str.replace(`{${i}}`, args[i])
    }
    return str
  }
}

export default new GlobalProperties()
