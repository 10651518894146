import buildClassAttribute from './build-class-attribute'
import buildDefaultAttributes from './build-default-attributes'
import buildEditableAttribute from './build-editable-attribute'

import { buildInlineStyle } from '../utils'

const linkButtonHelper = (prop, classList, editableProperty, controlType, index) => {
  // Build the list of classes needed for the element
  const classAttribute = buildClassAttribute(prop, classList)

  // tabindex is used to disable certain bootstrap styling which has 'not' selectors on tabindex
  const regularAttributes = buildDefaultAttributes('button', index)

  // Is an editable element
  const editableAttribute = buildEditableAttribute(editableProperty)

  // Style attribute
  const styleAttribute = prop.textStyle ? ` style="${buildInlineStyle(prop.textStyle)}"` : ''

  const attributes = `${classAttribute} ${regularAttributes} ${editableAttribute} ${styleAttribute}`
  const icon = 'icon' in prop && prop.icon.code ? `<i ${styleAttribute} class="fa ${prop.icon.code}"> </i>` : ''
  const textStyling = prop.textStyle ? buildInlineStyle(prop.textStyle) : ''

  const dataProp = `${editableProperty}.title`

  return `<button ${attributes}>
    ${icon}
    <div style="${textStyling}">${prop.title}<div
      data-editable="button"
      data-prop="${dataProp}"
      style="display:none;"></div>
    </div>
  </button>`
}

export default linkButtonHelper
