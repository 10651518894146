import ThemeEditorConfig from 'core/helpers/data/fonts/data/theme-editor-config.json'
import FontStyles from 'core/helpers/data/fonts/data/font-styles.json'
import map from 'lodash/map'
import find from 'lodash/find'

class FontHelper {
  constructor () {
    this.config = ThemeEditorConfig.fonts
    this.styles = FontStyles
  }

  getFontsAsArray () {
    return map(this.styles, (value, name) => {
      return {
        name,
        ...value
      }
    })
  }

  getHeadingFonts () {
    return this.getFontsAsArray().filter(i => i.pair)
  }

  getBodyFonts () {
    return this.getFontsAsArray().filter(i => find(this.styles, k => (k.pair && k.pair.name) === i.name))
  }

  getFontStyles () {
    const styles = {}
    let i = 0
    this.getFontsAsArray().forEach(font => {
      if (font.styles) {
        font.styles.forEach(style => {
          if (!styles[style]) {
            styles[style] = {
              name: style,
              fontPairs: [font],
              index: i++
            }
          } else {
            styles[style].fontPairs.push(font)
          }
        })
      }
    })
    return styles
  }

  fontToEditorObj = (font, currentFont) => {
    // const array = this.getFontsAsArray()
    return this.convertToFontObject({
      ...currentFont,
      heading: font,
      body: font.pair
    })
  }

  convertToFontObject = font => {
    const body = font.body
    const heading = font.heading

    return {
      body: {
        name: body.name,
        weight: body.weight
      },
      heading: {
        name: heading.name,
        weight: heading.weight
      },
      fontSize: font.fontSize
    }
  }

  getStyleIndexByObject = fontObject => {
    const styles = (this.styles[fontObject.heading.name] || {}).styles
    if (styles && styles.length) {
      return this.getFontStyles()[styles[0]].index
    }
    return -1
  }
}

export default new FontHelper()
