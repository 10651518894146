import map from 'lodash/map'
import Handlebars from 'handlebars'
import GlobalProperties from 'core/helpers/global/global-properties'
import { getPageUrl } from 'core/helpers/page-url-helper'
import { convertToPercentage } from 'core/helpers/data/fonts/font-size-converter'

export const buildInlineStyle = passedProp => {
  return map(passedProp || {}, (value, key) => {
    if (key === 'font-size') {
      value = convertToPercentage(value) + '%'
    }
    return `${key}: ${value}`
  }).join(';')
}

export const hasData = data => {
  if (!data || !data.data) {
    return false
  }

  return true
}

export const registerHelperWithDataAsFirstArgument = (name, func) => {
  // convert the argument order so that the data argument is always first. Otherwise the data argument is the last of the arguments in the helper which depents on the implementation in the html file
  Handlebars.registerHelper(name, function () {
    const newArguments = new Array(arguments.length)

    let offset = 1
    for (let i = 0; i < arguments.length; i++) {
      const argument = arguments[i]
      if (offset === 1 && (typeof argument === 'object' && argument.data && argument.data.root)) {
        offset = 0
        newArguments[0] = argument
      } else {
        newArguments[i + offset] = argument
      }
    }
    return func.apply(this, newArguments)
  })
}

export const constructData = (argData, argClassList) => {
  // Refactor - it's weird that data can be a in a classList argument.
  if (!argData && argClassList && argClassList.data) {
    return {
      data: argClassList,
      classList: ''
    }
  } else {
    return {
      data: argData,
      classList: argClassList
    }
  }
}

export const getHref = (prop, absolute = false) => {
  if (!prop) {
    return
  }

  if (prop.href && prop.href.indexOf('page:') === 0) {
    const page = GlobalProperties.getPageByIdOrPath(prop.href.split(':')[1])
    if (!page.uriPath) {
      console.warn(`page ${prop.href} could not be found in page list`)
    }
    let url = page.mainPage ? '/' : ((absolute ? '/' : '') + page.uriPath)

    url = getPageUrl(url)

    return url
  }

  if (prop.linkType === 'web' && prop.href) {
    return prop.href
  }

  if (prop.linkType === 'email' && prop.mailHref) {
    return prop.mailHref
  }

  if (prop.linkType === 'phone' && prop.telHref) {
    return prop.telHref
  }

  return prop.telHref || prop.mailHref || prop.href
}
