import Handlebars from 'handlebars'
import GlobalProperties from 'core/helpers/global/global-properties'

Handlebars.registerHelper('ifPopover', function (value) {
  if (GlobalProperties.isRuntime && this.showPopover) {
    return value.fn(this)
  } else {
    return null
  }
})
