import CoreLiveStyleSheet from 'core/editor/helpers/style-loader/live-style-sheet'

class LiveStyleSheet extends CoreLiveStyleSheet {
  constructor (stylesheetKey, css, isBaseStyling, layout, colorModel, currentZoom) {
    super(stylesheetKey, css, isBaseStyling, layout, colorModel)

    this.ruleAdjustments = { zoomFactor: 1, deltaWidth: 0 }
    if (currentZoom) {
      this.updateMediaQueriesWidths(currentZoom)
    }
  }

  updateMediaQueriesWidths (zoom) {
    const { deltaWidth, zoomFactor } = zoom
    const deltaZoomFactor = this.ruleAdjustments.zoomFactor / zoomFactor

    this.ruleAdjustments = zoom

    if (this.stylesheetKey === 'fontPairStyle') {
      // breaks under FF
      return
    }
    const rules = this.styleSheet.cssRules
    for (let i = 0; i < rules.length; i++) {
      if (rules[i].media && rules[i].media.mediaText) {
        const theRule = rules[i]
        let mediaQ = theRule.originalConditionText || theRule.media.mediaText
        theRule.originalConditionText = mediaQ

        // Media query selectors
        mediaQ = mediaQ.replace(/\(([min|max]+)-width: (\d+)px\)/gi, (line, minMax, size) =>
          line.replace(size, Math.max(1, ~~size + deltaWidth))
        )

        // Actual css values
        theRule.cssRules.forEach((rule, index) => {
          if (rule.cssText && rule.cssText.includes('vw')) {
            const newCssText = rule.cssText.replace(/([min|max]+)-width: (\d+)vw;/gi, (line, minMax, size) =>
              line.replace(size, Math.max(1, ~~size * deltaZoomFactor))
            )

            theRule.deleteRule(index)
            theRule.insertRule(newCssText, index)
          }
        })

        if (theRule.media) {
          theRule.media.mediaText = mediaQ
        }
      }
    }
  }
}
export default LiveStyleSheet
