////////////////
////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////

class LocalImageHelper {
  constructor () {
    this.localImages = {}
  }

  getImage (id) {
    return this.localImages[id]
  }

  handleSiteRestore = site => {
    // itterate through site and upload all and remove temp reference
    const promises = []
    recursiveIterateObjects(site, obj => {
      if (obj._localImage) {
        promises.push(
          new Promise((resolve, reject) => {
            LocalStorageAbstraction.getItem(obj._localImage)
              .then(item => {
                return this.uploadBase64Image(item).then(uploadUrl => {
                  obj.value = uploadUrl
                  // delete obj._localImage
                  resolve(obj)
                })
              })
              .catch(reject)
          })
        )
      }
    })
    return Promise.all(promises)
  }

//////////////////
////////////////////////////////////
////////////////////////////////////////

//////////////////////////////////////////
////////////////////////////////////////////////////////

//////////////////////////////////////////
//////////////////
///

////////////////////////////
////////////////////////////////////////
/////////////////////////////////
///

///////////////////////////////
///////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////

/////////////////////////////////////////////
////////////////////////////////////////
///////////////////////////////////
///////////////////////////
/////////////////////////
//////////////////////
//////////
/////////////////////
////////

////////////////////////////////////
///////////////////
///////

////////////////////////////////////////////
////////////////////////////////
///////////////////////////////////////////////////////
//////////////////////
////////////////////////////
/////////
///////
///////////////////////////
//////
///

/////////////////////////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////////////////////////////////

/////////////////////
/////////////////////////////////////
//////////////////////////////////////////
//////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////
////////////////////////////////////

//////////////////////////////////////
///////////////////////////////
//////////////////////////
///////////////////////////////
/////////////
////////////
///////////////////////////
////////
/////
///

////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
/////////////////////////////
//////////////////////

/////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
/////

////////////////////////////////////
///////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
///////
/////

////////////////////////////////////
//////////////////////////////////////////////////////

//////////////////////////////////////
////////////////////////////////////
////////////
/////////////////////////////
/////
///
///////////
}

export default new LocalImageHelper()
