import Handlebars from 'handlebars'

const buildEditableNode = (nodeName, attributes, content, propertyPath) => {
  return `<${nodeName} ${attributes}>
    ${content}
    <span
      data-prop="${propertyPath}"
      data-editable="basic"
      style="display:none;"></span>
  </${nodeName}>`
}

const textHelper = (property, nodeName, classList, editable, data) => {
  let binding

  if (classList && classList.data) {
    binding = classList.data.root
    classList = ''
  } else {
    binding = data.data && data.data.root
  }

  if (!binding) {
    return
  }
  const value = binding[property]

  if (!editable) {
    return `<${nodeName} class="${classList}">
      ${value}
    </${nodeName}>`
  }
  return buildEditableNode(nodeName, `class="${classList}" data-type="text"`, value, property)
}

export default (property, nodeName, classList, editable = true, data) => {
  // This will return an editable node (check helper util - buildEditableNode)
  return new Handlebars.SafeString(textHelper(property, nodeName, classList, editable, data))
}
