import BaseControl from 'editor/controls/base-control'

class NavigationControl extends BaseControl {
  constructor (controller, model) {
    super(controller, controller.getSectionController().getNavigationLayout, model, 'navigation')
  }

  bindLayout (element, data) {
    // inject custom header buttons from here (shopping cart)
    this.controller.getSiteController().customHeaderButtons.afterHeaderRender(element)

    super.bindLayout(element, data)
  }
}
export default NavigationControl
