export default (items, key, data) => {
  const itemElements = items
    .map((item, i) => `<div class="fe-dropdown-item" data-id="${i}">${item.label}</div>`)
    .join('')

  return `<div class="fe-dropdown-container" data-id="${key}">
    <div class="fe-dropdown-current-value">...</div>
    <div class="fe-dropdown">${itemElements}</div>
  </div>`
}
