import CoreBaseControl from 'core/editor/controls/base-control'

// Mixins
import BlurInImageMixin from './mixin/blur-in-images-mixin'
import LazyVideosMixin from './mixin/lazy-videos-mixin'
import SiteRenderer from './mixin/site-renderer'
////////////////
//////////////////////////////////////////////////////////////
/////////

class BaseControl extends CoreBaseControl {
  constructor (controller, layout, model, controlType) {
    const editorMixins = IS_EDITOR ? [MediumEditMixin] : []
    const mixins = [...editorMixins, BlurInImageMixin, LazyVideosMixin, SiteRenderer]

    super(controller, layout, model, controlType, mixins)
  }
}
export default BaseControl
