const mathOperators = {
  '+': (a, b) => a + b,
  '-': (a, b) => a - b,
  '*': (a, b) => a * b,
  '/': (a, b) => a / b,
  '%': (a, b) => a % b,
  '++': a => a + 1,
  '--': a => a - 1
}

const calculate = (a, operator, b, block) => {
  if (!mathOperators.hasOwnProperty(operator)) {
    throw new Error(`Operator '${operator}' is not supported in the calc/calculate handlebar helper.`)
  }

  return mathOperators[operator](a, b)
}

export default calculate
