import fetch from 'isomorphic-fetch'
import GetCurrentLanguage from '@eig-builder/core-utils/helpers/current-language-helper'
import Store from '@eig-builder/core-utils/store'

class FetchError {
  constructor (obj) {
    this.status = obj.response.status
    this.payload = obj.payload
  }
}

class Fetch {
  static postAsJson (url, data, options) {
    const obj = {
      returnType: 'json',
      ...options
    }

    const headers = obj.fetchOptions && obj.fetchOptions.headers

    const xsrfTokenFromState = Store().getState().auth && Store().getState().auth.xsrfToken

    obj.fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': xsrfTokenFromState,
        ...headers
      },
      body: JSON.stringify(data)
    }
    return Fetch.callFetch(url, obj)
  }

  static fetchAsJson (url, options) {
    const obj = { ...options }
    obj.returnType = 'json'

    return Fetch.callFetch(url, obj)
  }

  static callFetch (url, options) {
    options.fetchOptions = { ...options.fetchOptions }

    if (!options.skipCredentials) {
      // Include credentials header / cookies in the fetch
      options.fetchOptions.credentials = 'include'

      // set current Language in request
      const language = GetCurrentLanguage()
      options.fetchOptions.headers = { ...options.fetchOptions.headers, 'Accept-Language': language }
    }

    let responsePromise = fetch(url, options.fetchOptions)

    responsePromise = responsePromise.then(res => {
      // Convert response to text and return original response + data
      return res.text().then(data => ({ response: res, data: data }))
    })
    return responsePromise
      .then(result => {
        const obj = {
          response: result.response,
          payload: result.data
        }

        // Check if returnType is defined, if not, skip the 'parsing' and fallback to text
        if (typeof options.returnType === 'undefined' || options.returnType === null || options.returnType === '') {
          return obj
        }

        // Convert text to the return type (only JSON supported for now)
        if (options.returnType === 'json') {
          if (obj.payload.length > 0) {
            obj.payload = JSON.parse(obj.payload)
          }
        } else if (options.returnType === 'text') {
          return obj
        } else {
          // Or... Returntype was defined, but not supported yet, throw a message
          console.log(`Returntype ${options.returnType} not supported, fallback to text`)
        }

        return obj
      })
      .then(result => {
        if (result.response.status >= 400) {
          throw new FetchError(result)
        }
        return result.payload
      })
      .catch(error => {
        // Firefox throws a TypeError when navigating to a page when a request is incomplete
        // we dont want to throw a message when this happends.
        // https://github.com/github/fetch/issues/310
        // https://developer.mozilla.org/nl/docs/Web/JavaScript/Reference/Global_Objects/TypeError
        // const dontLog = error.constructor === TypeError
        throw error
      })
  }
}

export default Fetch
