const facebookVideoHelper = (prop, property, classList) => {
  return `
    <div
      data-type="video"
      data-property="${property}"
      class="${classList}"
    >
      <div class="video-thumbnail" style="background-image:url(//graph.facebook.com/${prop.videoId}/picture);background-size: cover;width: 100%;padding-top: 56.25%" ></div>
    </div>`
}

export default facebookVideoHelper
