import { iterateAllSectionsForSite, temporaryRemoveFeaturesFromPage } from 'core/editor/helpers/site-model-helper'

// removes feature names from the site and returns a function to restore it
const temporaryRemoveFeaturesFromSite = (site, features) => {
  const replacePages = []
  const restoreFuncs = []
  const removedPageIds = []

  site.pages.forEach(page => {
    if (page.sections.length > 0) {
      restoreFuncs.push(temporaryRemoveFeaturesFromPage(page, features))
      if (page.sections.length > 0) {
        replacePages.push(page) // keep this page in the site
      } else {
        removedPageIds.push(page.id + '')
      }
    } else {
      replacePages.push(page)
    }
  })

  const original = site.pages
  const originalNavigation = site.navigation
  site.pages = replacePages
  if (site.navigation) {
    site.navigation = site.navigation.filter(i => !i.pageId || removedPageIds.indexOf(i.pageId + '') < 0)
  }
  return () => {
    site.pages = original
    site.navigation = originalNavigation
    // restore all sections
    restoreFuncs.filter(i => i).forEach(func => func())
  }
}

export { iterateAllSectionsForSite, temporaryRemoveFeaturesFromSite }
