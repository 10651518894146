import CoreSectionController from 'core/editor/controllers/section-controller'

class SectionController extends CoreSectionController {
  // TODO: @dvandervlag @pkiers disabled because of decorations.
  // getPreviousSectionController = () => {
  //   const currentSectionId = this.model.id
  //   const currentSectionIndex = this.parentController.controlDictionary?.values?.findIndex(sectionControl => sectionControl.model?.id === currentSectionId)

  //   if (currentSectionIndex > 0) {
  //     return this.parentController.controlDictionary?.values[currentSectionIndex - 1]
  //   }
  //   return null // There is no previous section controller, as currently we have the header selected.
  // }

  innerGetValue (model) {
    const result = super.innerGetValue(model)

    // Render non-main-page headers inside <head> element, not in <section> for fixed navigation bar
    const isHeaderSection = this.getPageController().model.headerSection === model
    result.renderBackground = !isHeaderSection || model.binding.cover
    return result
  }
}
export default SectionController
