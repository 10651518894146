import { makeTempImageAndPrepareStoringImage } from 'core/helpers/images/upload-replace-image-helper'
import maxBy from 'lodash/maxBy'

class ImagePickLogic {
  constructor (images) {
    this.imageIndex = ~~(Math.random() * images.length)
    this.images = images
    this.pickPrettyImages = images.filter(i => i.rating > 1).length
  }

  pickImage = (isBackground, isCover) => {
    if (!this.images || !this.images.length) {
      // return a random abstract image if we have none
      return {
        value: 'https://images.unsplash.com/photo-1550684848-fac1c5b4e853?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80'
      }
    }

    let result
    if (isCover) {
      // cover = select highest background-rating
      result = maxBy(this.images, i => i.backgroundRating)
    } else {
      let tries = 0
      if (isBackground) {
        do {
          this.imageIndex = ~~(this.imageIndex + 1) % this.images.length
          // pick next image, if the background-rating is higher then 1, otherwise repick
        } while (tries++ < 100 && this.images[this.imageIndex].backgroundRating < 1)
      } else {
        do {
          this.imageIndex = ~~(this.imageIndex + 1) % this.images.length
        } while (this.pickPrettyImages > 0 && tries++ < 100 && this.images[this.imageIndex].rating <= 1)
      }
      this.pickPrettyImages--
      result = this.images[this.imageIndex]
    }

    // build the result model
    const obj = {
      value: result.url,
      altText: result.alt
    }
    if (result.source === 'Facebook') {
      makeTempImageAndPrepareStoringImage(obj, 'value')
    }
    if (result.size) {
      obj.originalSize = result.size
    }
    if (result.authorUsername && result.authorName) {
      obj.authorUsername = result.authorUsername
      obj.authorName = result.authorName
    }

    return obj
  }
}

export default ImagePickLogic
