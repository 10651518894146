function componentToHex (c) {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

function rgbToHex (r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

function hexToRgb (hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ]
    : [0, 0, 0]
}

function parseColor (color) {
  if (Array.isArray(color)) {
    return rgbToHex(...color)
  }
  return color
}

function compareRgb (a, b) {
  return a[0] === b[0] && a[1] === b[1] && a[2] === b[2]
}

function getClosestInverseColor (colors, c1) {
  // Create an array of competing colors.
  const c1i = colors.findIndex(c => compareRgb(c, c1))
  const compareColors = colors.filter((c, i) => i !== c1i)

  // Calculate distance between every other color and c1.
  const dst = compareColors.map((c2, i) => {
    return (
      ((c2[0] - c1[0]) * 0.3) ^
      (2 + (c2[1] - c1[1]) * 0.59) ^
      (2 + (c2[2] - c1[2]) * 0.11) ^
      2
    )
  })

  // Calculate contrast between every other color and c1.
  const ctr = compareColors.map((c2, i) => {
    return contrast(c1, c2)
  })

  // Calculate contrast between every color and white.
  const white = [255, 255, 255]
  const ctrw = compareColors.map((c2, i) => {
    return contrast(c2, white)
  })

  // Calculate contrast between every color and black.
  const black = [0, 0, 0]
  const ctrb = compareColors.map((c2, i) => {
    return contrast(c2, black)
  })

  // Normalize values to a 0 - 1 range and divide by 2.
  const dstNormalized = dst.map(d => (d / Math.max(...dst)))
  const ctrNormalized = ctr.map(c => (c / Math.max(...ctr)))
  const ctrwNormalized = ctrw.map(c => (c / Math.max(...ctrw)))
  const ctrbNormalized = ctrb.map(c => (c / Math.max(...ctrb)))

  const result = []
  // Sum distanceScore + contrastScore for every other color.
  const finalScores = compareColors.map((c, i) => {
    result[i] = {
      ...c,
      distance: dstNormalized[i],
      contrast: ctrNormalized[i],
      contrastWithWhite: ctrwNormalized[i],
      contrastWithBlack: ctrbNormalized[i]
    }
    return (
      dstNormalized[i] +
      ctrNormalized[i] +
      ctrwNormalized[i] +
      ctrbNormalized[i]
    )
  })

  // Get the color with the highest score (highest contrast and farthest distance).
  let winner = 0
  let closestColor
  compareColors.forEach((c, i) => {
    const score = finalScores[i]
    if (score > winner) {
      winner = score
      closestColor = c
    }
  })

  // Return the winner.
  return colors[colors.findIndex(c => compareRgb(c, closestColor))]
}

function luminance (r, g, b) {
  const a = [r, g, b].map(function (v) {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

function contrast (rgb1, rgb2) {
  let result = (
    (luminance(rgb1[0], rgb1[1], rgb1[2]) + 0.05) /
    (luminance(rgb2[0], rgb2[1], rgb2[2]) + 0.05)
  )
  if (result < 1) {
    result = 1 / result
  }
  return result
}

export {
  rgbToHex,
  hexToRgb,
  parseColor,
  compareRgb,
  getClosestInverseColor,
  luminance,
  contrast
}
