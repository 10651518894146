const socialProviders = {
  behance: {
    name: 'Behance',
    fa_icon: 'fa-behance'
  },
  dribbble: {
    name: 'Dribbble',
    fa_icon: 'fa-dribbble'
  },
  etsy: {
    name: 'Etsy',
    fa_icon: 'fa-etsy'
  },
  facebook: {
    name: 'Facebook',
    fa_icon: 'fa-facebook-official'
  },
  github: {
    name: 'GitHub',
    fa_icon: 'fa-github'
  },
  google_plus: {
    name: 'Google+',
    fa_icon: 'fa-google-plus-official'
  },
  instagram: {
    name: 'Instagram',
    fa_icon: 'fa-instagram'
  },
  linkedin: {
    name: 'LinkedIn',
    fa_icon: 'fa-linkedin-square'
  },
  meetup: {
    name: 'Meetup',
    fa_icon: 'fa-meetup'
  },
  pinterest: {
    name: 'Pinterest',
    fa_icon: 'fa-pinterest'
  },
  snapchat: {
    name: 'Snapchat',
    fa_icon: 'fa-snapchat-ghost'
  },
  soundcloud: {
    name: 'SoundCloud',
    fa_icon: 'fa-soundcloud'
  },
  telegram: {
    name: 'Telegram',
    fa_icon: 'fa-telegram'
  },
  tumblr: {
    name: 'Tumblr',
    fa_icon: 'fa-tumblr'
  },
  twitter: {
    name: 'Twitter',
    fa_icon: 'fa-twitter'
  },
  youtube: {
    name: 'YouTube',
    fa_icon: 'fa-youtube-play'
  },
  opentable: {
    name: 'OpenTable',
    svg_icon:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 535 512"><circle cx="48.64" cy="256" r="48.64"/><path d="M340.45,61.45C233,61.45,145.91,148.55,145.91,256S233,450.55,340.45,450.55,535,363.44,535,256,447.9,61.45,340.45,61.45Zm0,243.19A48.64,48.64,0,1,1,389.09,256,48.64,48.64,0,0,1,340.45,304.64Z"/></svg>'
  },
  googleplus: {
    name: 'Google+',
    fa_icon: 'fa-google-plus-square'
  },
  spotify: {
    name: 'Spotify',
    fa_icon: 'fa-spotify'
  },
  itunes: {
    name: 'iTunes',
    fa_icon: 'fa-music'
  },
  bandsintown: {
    name: 'Bandsintown',
    fa_icon: 'fa-share-square'
  },
  singleplatform: {
    name: 'SinglePlatform',
    svg_icon:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M296.69,219.06c0,59.47-72.69,138.76-72.69,138.76s-72.69-79.29-72.69-138.76a72.69,72.69,0,0,1,145.38,0Z"/><path d="M104.53,215.28a119.3,119.3,0,0,1,206.62-81.46H441.57A6.42,6.42,0,0,0,448,127.4v-89A6.43,6.43,0,0,0,441.57,32H6.43A6.43,6.43,0,0,0,0,38.43V331a6.43,6.43,0,0,0,6.43,6.43H151.24C128.28,303.57,104.53,258.2,104.53,215.28Z"/><path d="M336.16,174.55a118.63,118.63,0,0,1,7.31,40.73c0,74.73-72,157.07-86.45,172.81l-33,36-33-36c-1.95-2.12-5-5.54-8.73-9.9H6.43A6.42,6.42,0,0,0,0,384.61v89A6.42,6.42,0,0,0,6.43,480H441.57a6.42,6.42,0,0,0,6.43-6.42V181a6.43,6.43,0,0,0-6.43-6.43Z"/></svg>'
  },
  foursquare: {
    name: 'Foursquare',
    fa_icon: 'fa-foursquare'
  },
  yelp: {
    name: 'Yelp',
    fa_icon: 'fa-yelp'
  },
  default: {
    name: 'Undefined', // To point out that a social provider is not added to the providers list.
    fa_icon: 'fa-share-square',
    link: '{account}'
  }
}

export default socialProviders
