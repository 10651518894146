/// used to add custom header buttons from features. Used for the shopping button in the store features
import each from 'lodash/each'

import Features from 'editor/managers/features'

const makeDomElement = html => {
  const template = document.createElement('div')
  template.innerHTML = html
  return template.childNodes[0]
}

class CustomHeaderButton {
  constructor (forEachPageElement) {
    this.customHeaderButtons = {}
    this.forEachPageElement = forEachPageElement
  }

  add (id, html) {
    if (this.customHeaderButtons[id]) {
      return false
    }
    this.customHeaderButtons[id] = html
    this.forEachPageElement(element => {
      this.injectHtml(element, id, html)
    })
  }

  afterHeaderRender (element) {
    each(this.customHeaderButtons, (html, id) => {
      this.injectHtml(element, id, html)
    })
  }

  remove (id) {
    if (this.customHeaderButtons[id]) {
      delete this.customHeaderButtons[id]
      this.forEachPageElement(element => {
        this.iterate(element, id, node => node.remove())
      })
    }
  }

  iterateAll (id, onVisit) {
    this.forEachPageElement(element => {
      this.iterate(element, id, onVisit)
    })
  }

  iterate (element, id, onVisit) {
    const array = element.querySelectorAll('[data-custom-header-button]')
    for (let i = 0; i < array.length; i++) {
      if (array[i].getAttribute('data-custom-header-button') === id) {
        onVisit(array[i])
      }
    }
  }

  injectHtml (element, id, html) {
    const templates = element.querySelectorAll('#custom-header-button-template')
    for (let i = 0; i < templates.length; i++) {
      const newButton = makeDomElement(html)
      // copy classlist of template for styling and positioning
      newButton.classList.value += ` ${templates[i].classList.value}`
      newButton.setAttribute('data-custom-header-button', id)

      Features.afterRenderCustomHeaderButton(id, newButton)

      templates[i].parentElement.insertBefore(newButton, templates[i])
    }
  }
}
export default CustomHeaderButton
