import Handlebars from 'handlebars'

export default (name, index, control, data) => {
  // used in each loop or like {{control "name"}}
  if (!control) {
    data = index
    control = data.data.root._getControl(name)
  }

  return new Handlebars.SafeString(`${IS_MAIL ? '<mj-raw>' : ''}<div class="kv-control-placeholder kv-_${
    data.data.root._controlType
  }" data-kv-control="${name || index}"></div>${IS_MAIL ? '</mj-raw>' : ''}
      ${control.renderHtml(data.data.root, index)}`)
}
