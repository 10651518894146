class Dictionary {
  constructor () {
    this.collection = {}
    this.length = 0
  }

  set (key, value) {
    this.collection[key] = value
    this.length++
  }

  get (key) {
    if (!this.exists(key)) {
      return null
    }
    return this.collection[key]
  }

  remove (key) {
    if (this.exists(key)) {
      delete this.collection[key]
      this.length--
    }
  }

  exists (key) {
    return this.collection.hasOwnProperty(key)
  }

  forEach (cb) {
    Object.entries(this.collection).forEach(([key, value], i) => {
      cb(value, key, i)
    })
  }
}

export default Dictionary
