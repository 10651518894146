// Values of what L / M / S means
const LARGE = 1.125
const MEDIUM = 1
const SMALL = 0.875

// Export this so the changes are global
export const fontSizes = {
  s: SMALL,
  m: MEDIUM,
  l: LARGE
}

export const convertToPercentage = value => {
  if (!value) {
    return value
  }
  return (value * 100) + 12.5
}

export const convertToPixels = value => {
  if (!value) {
    return value
  }

  // small is about 16, big is 20
  return (value - MEDIUM) / (LARGE - MEDIUM) * 2 + 18
}
