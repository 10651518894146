import each from 'lodash/each'

import { getFeatureSettings } from 'editor/managers/features/feature-helpers'

let activeFeatures = {} // the feature instances active in your site
let activeFeaturesForPreview = {}

class ActiveFeature {
  constructor (controller, FeatureClass, featureName, isForPreview, isOnboarding, dependingFeatures) {
    this.featureName = featureName
    this.FeatureClass = FeatureClass
    this.instances = []
    this.featureControlRefs = [] // for every control that has a feature that control has a .feature property, this needs to be reffed and updated for the removal a refreshing of features

    this.dependingFeatures = dependingFeatures
    this.isForPreview = isForPreview
    this.isOnboarding = isOnboarding
    this.controller = controller

    if (FeatureClass.activate) {
      // call static initialize
      FeatureClass.activate(this.getConfig(), this.getDependencies())
    }
  }

  getGlobalInstance () {
    if (!this.instances.length) {
      // create the global instance
      const site = this.controller.getSiteController()
      return this.createInstance(site.control, site.model.globalFeatureModel)
    } else {
      return this.instances[0]
    }
  }

  getConfig (control, model = null) {
    return {
      isThumbnail: this.isForPreview,
      isRuntimeSite: !IS_EDITOR,
      isOnboarding: this.isOnboarding,
      view: control,
      model: model || control.model
    }
  }

  getDependencies () {
    const result = {}
    if (this.dependingFeatures) {
      this.dependingFeatures.forEach(i => {
        result[i.featureName] = i.getGlobalInstance()
      })
    }
    return result
  }

  createInstance (control, model) {
    if (!control.features) {
      control.features = {}
    }
    const controller = control.controller
    const api = new controller.imports.EditorFeatureSDK(controller, this.featureName, this.isForPreview)
    const instance = new this.FeatureClass(api, this.getConfig(control, model), this.getDependencies())

    if (this.featureControlRefs.indexOf(control.features) < 0) {
      this.featureControlRefs.push(control.features)
    }
    this.instances.push(instance)
    control.features[this.featureName] = instance

    return instance
  }

  dispose () {
    const array = ActiveFeature.getActiveFeatures(this.isForPreview)
    delete array[this.featureName]

    // unload feature if it was previously loaded
    this.instances.forEach(instance => {
      if (instance.dispose) {
        instance.dispose()
      }
    })
    this.featureControlRefs.forEach(ref => {
      ref[this.featureName] = null
    })

    // call global deactivate
    if (this.FeatureClass.deactivate) {
      this.FeatureClass.deactivate()
    }

    // dispose all children
    ActiveFeature.getChildFeatureNames(this.featureName).forEach(name => {
      const child = ActiveFeature.getActiveFeatures(this.isForPreview)[name]
      if (child) {
        child.dispose()
      }
    })
  }

  getChildFeatures () {
    const result = []
    ActiveFeature.getChildFeatureNames(this.featureName, getFeatureSettings).forEach(name => {
      const siteContoller = this.controller.getSiteController()
      ActiveFeature.forEachFeatureInstance(siteContoller, name, feature => result.push(feature))
    })
    return result
  }

  static deactivateFeature (featureName) {
    if (activeFeatures[featureName]) {
      activeFeatures[featureName].dispose()
    }
  }

  static killAll () {
    activeFeatures = {}
    activeFeaturesForPreview = {}
  }

  static getActiveFeatures (isForPreview) {
    return isForPreview ? activeFeaturesForPreview : activeFeatures
  }

  static forEachFeatureByAppmarketSku (siteController, appmarketSku, callback) {
    siteController.forEachContentControls(true, contentControl => {
      if (contentControl.features) {
        each(contentControl.features, (instance, featureName) => {
          const settings = getFeatureSettings(featureName).settings
          if (settings && settings.appmarketApp === appmarketSku) {
            callback(contentControl.features[featureName])
          }
        })
      }
    })
  }

  static forEachFeatureInstance (siteController, featureName, callback) {
    siteController.forEachContentControls(true, contentControl => {
      if (contentControl.features && contentControl.features[featureName]) {
        callback(contentControl.features[featureName])
      }
    })
  }

  static getChildFeatureNames (featureName) {
    const result = []
    each(window.__features, (featureClass, name) => {
      const feat = getFeatureSettings(name)
      if (feat && feat.settings && feat.settings.dependingGlobalFeatures) {
        if (feat.settings.dependingGlobalFeatures.indexOf(featureName) >= 0) {
          result.push(name)
        }
      }
    })
    return result
  }
}
export default ActiveFeature
