const videoHelper = (src, ratio) => {
  return `
    <div style='
      height:0;
      width: 100%;
      padding-top:${ratio}%;
      position:relative;
    '>
      <iframe
        src='${src}'
        width='100%' height='100%'
        style='
          border:none;
          overflow:hidden;
          position:absolute;
          top:0;
          left:0;
          right:0;
          bottom:0;
        '
        scrolling='no'
        frameborder='0'
        allowTransparency='true'
        allowFullScreen='true'> </iframe>
   </div>`
}

export default videoHelper
