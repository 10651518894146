const UNSAFE_CHARS_REGEXP = /[<>\/\u2028\u2029]/g // eslint-disable-line
const ESCAPED_CHARS = {
  // eslint-disable-line
  '<': '\\u003C',
  '>': '\\u003E',
  '/': '\\u002F',
  '\u2028': '\\u2028',
  '\u2029': '\\u2029'
}

const escapeUnsafeChars = unsafeChar => {
  return ESCAPED_CHARS[unsafeChar]
}

const sanitizeInput = value => {
  let newValue = value
  if (newValue && newValue.length) {
    if (typeof newValue === 'object') {
      // Make sure we stringify for other object cases
      newValue = JSON.stringify(newValue)
    }

    newValue = newValue.replace(UNSAFE_CHARS_REGEXP, escapeUnsafeChars)
  }

  return newValue
}

export default sanitizeInput
