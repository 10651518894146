import each from 'lodash/each'
import remove from 'lodash/remove'
import find from 'lodash/find'

let listeners = []
let designer

class Communication {
  static clearAll () {
    listeners = []
  }

  static registerDesignerCommunication (DesignerCommunication) {
    designer = DesignerCommunication
  }

  static createMessagge (type, data) {
    return {
      type,
      data
    }
  }

  static sendRedux (key, payload) {
    Communication.send('redux-action', {
      reduxKey: key,
      payload
    })
  }

  static send (type, data) {
    if (process.env.NODE_ENV === 'development') {
      console.log(`%csend ${type} to designer`, 'color: #aa7700', data)
    }

    if (designer) {
      designer.doReceiveMessage({ data: Communication.createMessagge(type, data) })
    } else {
      if (window.parent) {
        window.parent.postMessage(Communication.createMessagge(type, data), '*')
      }
    }
  }

  // send a fake message (used in layout-maker)
  static stubReceiveMessage (type, data) {
    return Communication.doReceiveMessage({ data: Communication.createMessagge(type, data) })
  }

  static doReceiveMessage (message) {
    let result
    if (message.data && message.data.type) {
      // console.log('receive: ' + message.data.type)
      // console.log(message.data.data)
      each(listeners, i => {
        if (i && i.type === message.data.type) {
          result = i.listener(message.data.data)
        }
      })
    }
    return result
  }

  static onReceiveMessage (listener, filterType) {
    Communication.on(listener, filterType)
  }

  static offRespond (filterType) {
    remove(listeners, i => i.type === filterType)
  }

  static request (type, data, callback, iframe) {
    let doReceive = null
    doReceive = data => {
      if (callback) {
        callback(data)
      }
      Communication.off(doReceive, iframe)
    }
    Communication.on(doReceive, type, iframe)
    Communication.send(type, data, iframe)
  }

  static onRespond (getter, filterType, withCallback) {
    // we only can have one respond type per message type
    if (find(listeners, i => i.type === filterType)) {
      throw new Error(`Already a respond type defined for ${filterType}`)
    }

    Communication.on(data => {
      if (withCallback) {
        getter(data, response => {
          Communication.send(filterType, response)
        })
      } else {
        Communication.send(filterType, getter(data))
      }
    }, filterType)
  }

  static on (listener, filterType) {
    if (typeof listener !== 'function') {
      throw new Error('listener should be a function')
    }
    listeners.push({
      listener,
      type: filterType
    })
  }

  static offReceiveMessage (listener) {
    Communication.off(listener)
  }

  static off (listener) {
    remove(listeners, i => i.listener === listener)
  }
}

if (window) {
  if (window.addEventListener) {
    window.addEventListener('message', Communication.doReceiveMessage, false)
  } else {
    window.attachEvent('onmessage', Communication.doReceiveMessage)
  }
}
export default Communication
