const subControlHelper = (name, index, data) => {
  const control = data.data.root._getControl(name)

  return `<div
    class="kv-control-placeholder kv-_${data.data.root._controlType}"
    data-kv-control="${name || index}">
  </div> ${control.renderHtml(data.data.root, index)}`
}

export default subControlHelper
