import LayoutHelper from './layout-helper'

const iterateAllSectionsForPage = (page, callback) => {
  if (page.sections) {
    page.sections.forEach(section => {
      callback(section, page)
    })
  }
  if (page.headerSection) {
    callback(page.headerSection, true)
  }
}

const iterateAllSectionsForSite = (site, callback) => {
  if (site.pages) {
    // Loop through the pages to find the sections that need updating
    site.pages.forEach(page => {
      iterateAllSectionsForPage(page, callback)
    })
  }
  if (site.globalSections && site.globalSections.footer) {
    callback(site.globalSections.footer)
  }
}

const temporaryRemoveFeaturesFromPage = (page, features) => {
  const replaceSections = []
  page.sections.forEach(section => {
    const sectionLayout = LayoutHelper.getLayoutObj(section.layout, 'section')
    const featureNames = sectionLayout && sectionLayout.metadata && sectionLayout.metadata.features
    if (!featureNames || !featureNames.some(i => features.indexOf(i) >= 0)) {
      replaceSections.push(section)
    }
  })

  if (replaceSections.length < page.sections.length) {
    const original = page.sections
    page.sections = replaceSections
    // return function to reset the state
    return () => {
      page.sections = original
    }
  }
}

export { iterateAllSectionsForSite, temporaryRemoveFeaturesFromPage }
