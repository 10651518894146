import GlobalBindingHelper from 'core/helpers/managers/global-binding'

export default class LocationHelper {
  constructor (globalBinding) {
    this.global = globalBinding
  }

  getLocationIds () {
    if (this.global.locations) {
      return Object.keys(this.global.locations)
    }
    return []
  }

  getLocationBinding (id) {
    return this.global.locations[id]
  }

  setLocationBinding (id, values) {
    this.global.locations[id] = values
  }

  getAddresses () {
    if (this.global.hasOwnProperty('address') && Object.keys(this.global.address).length > 0) {
      const address = this.global.address

      // Required properties.
      if (address.city && address.zip && address.state) {
        return [address]
      }
      return []
    } else if (this.global.hasOwnProperty('locations')) {
      return Object.values(this.global.locations).map(location => {
        if (location.address) {
          return { ...location.address, companyName: location.companyName }
        }
        return null
      })
    }
    return []
  }

  addLocation (fromId, name) {
    const location = {
      companyName: name,
      address: {},
      openingHours: {
        mon: [{ description: 'Closed' }],
        tue: [{ description: 'Closed' }],
        wed: [{ description: 'Closed' }],
        thu: [{ description: 'Closed' }],
        fri: [{ description: 'Closed' }],
        sat: [{ description: 'Closed' }],
        sun: [{ description: 'Closed' }]
      }
    }

    if (!this.global.locations || !Object.keys(this.global.locations).length) {
      const originalLocation = {}
      // put globalbinding in location array
      GlobalBindingHelper.getLocationFields().forEach(i => {
        originalLocation[i] = this.global[i]
        delete this.global[i]
      })
      if (!originalLocation.companyName) {
        originalLocation.companyName = 'business1'
      }
      this.global.locations = {
        1: originalLocation
      }
    }

    const key = new Date().valueOf().toString()
    this.global.locations[key] = location
    return key
  }

  removeLocation (locationId) {
    delete this.global.locations[locationId]

    const keyArray = Object.keys(this.global.locations)
    if (keyArray.length === 1) {
      // copy back to normal global binding
      const locationObj = this.global.locations[keyArray[0]]
      Object.keys(locationObj).forEach(key => {
        const val = locationObj[key]
        this.global[key] = val
      })
      delete this.global.locations
    }
  }
}

export const getFirstLocation = location => {
  if (location['1']) {
    return location['1']
  }

  const keys = Object.keys(location)
  if (keys && keys.length) {
    return location[keys[0]]
  }

  return null
}
