import imports from './readonly-imports'
import './default-styling.css'

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import 'swiper/dist/css/swiper.min.css'

import Velocity from 'velocity-animate'
import 'jquery-ui/themes/base/core.css'
import 'jquery-ui/themes/base/base.css'
import 'jquery-ui/themes/base/all.css'

import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-ui/ui/widgets/tabs'

import 'core/style/feature-notifications.scss'
import 'core/style/feature-modals.scss'

import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'

import Swiper from 'swiper/dist/js/swiper.js'

import GoogleMapsApi from 'core/editor/helpers/maps/google-maps-api'
import DataSourceHelper from '@eig-builder/core-utils/helpers/datasource/datasource-helper'
import DataProxyHelper from '@eig-builder/core-utils/helpers/dataproxy-helper'
import Communication from 'core/editor/helpers/global/communication'
import EditorFeatureSDK from 'editor/managers/feature-sdk/editor-feature-sdk'

export default {
  ...imports,
  Velocity,
  PhotoSwipe,
  PhotoSwipeUIDefault,
  Swiper,
  GoogleMapsApi,
  DataSourceHelper,
  DataProxyHelper,
  Communication,
  EditorFeatureSDK
}
