const getPageChildren = (navigation, checkNavigationItem, selector) => {
  if (!checkNavigationItem.page) {
    return []
  }

  const { page } = checkNavigationItem
  const id = page.id
  const isBlog = page.pageType === 'blog-overview/blog-overview'
  const isStore = page.pageType === 'store/landing-page'
  const result = []

  navigation.forEach((navigationItem, index) => {
    const { page } = navigationItem

    if (
      page?.parentId === id ||
      (isBlog && page && page.pageType === 'blog-detail/blog-detail') || // Fallback
      (isStore && page && page.pageType === 'store/product-detail') // Fallback
    ) {
      result.push(selector(index))
    }
  })

  return result
}

const isChildPage = (checkNavigationItem) => {
  if (!checkNavigationItem.page) {
    return
  }
  const { page } = checkNavigationItem

  if (page) {
    if (
      page.parentId ||
      page.pageType === 'blog-detail/blog-detail' ||
      page.pageType === 'store/product-detail'
    ) {
      return checkNavigationItem
    }
  }
}

const makePagesHierarchy = navigation => {
  const pageHierarchy = navigation.map(item => {
    const newItem = { ...item, children: item.children }

    return newItem
  }) // clone shallow because we need to keep the children intact

  pageHierarchy.forEach((page, index) => {
    page.children = getPageChildren(navigation, navigation[index], index => pageHierarchy[index])
  })

  const updatedPageHierarchy = pageHierarchy.filter((item, index) => !isChildPage(navigation[index]))
  return updatedPageHierarchy
}

export { makePagesHierarchy }
