import BaseControl from 'editor/controls/base-control'

class ListItemControl extends BaseControl {
  constructor (controller, layout, model) {
    super(controller, layout, model, 'list-item')
  }

  bindLayout (element, data, Layout) {
    super.bindLayout(element, data)
    element.dataset.type = 'item'

////////////////////
/////////////////////////////////////
////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
///////
/////
/////////////
  }

  onRemove (event) {
    this.controller.parentController.removeListItemControl(event, this)
  }

  getChildControls () {}
}
export default ListItemControl
