import CoreContentControl from 'core/editor/controls/content-control'

import { getClipPath } from 'editor/helpers/handlebars/helpers/image-mask'

class ContentControl extends CoreContentControl {
  // Make sure we always get an aspectRatio
  addAspectRatio (element, classString) {
    const aspectRatioElement = element.querySelector('[data-aspect-ratio]')
    if (aspectRatioElement && !aspectRatioElement.classList.contains(classString)) {
      aspectRatioElement.classList.add(classString)
    }
  }

  bindLayout (element, data) {
    // Content offset..
    if (this.model.offset) {
      element.style.paddingTop = `${this.model.offset}px`
    }

    if (this.model.contentTopOffset) {
      element.style.marginTop = `-${Math.abs(this.model.contentTopOffset)}px`
    }

    if (this.model.aspectRatio) {
      this.addAspectRatio(element, this.model.aspectRatio)

      if (this.model.aspectRatio === 'blob') {
        const replaceClipPath = element.querySelector('path[d=""]')
        if (replaceClipPath) {
          replaceClipPath.setAttribute('d', getClipPath(data))
        }
      }
    } else {
      this.addAspectRatio(element, 'filled')
    }
    super.bindLayout(element, data)
  }
}
export default ContentControl
