// Helper that converts font-size to S / M / L
import { fontSizes } from 'core/helpers/data/fonts/font-size-converter'

const needsConversion = value => {
  if (typeof value === 'number' && value < 3) { // check for < 3, converted fontsizes are between ~0.75 - ~1.25
    return false
  }
  return true
}

const convertButtonFontSize = oldSize => {
  if (!needsConversion(oldSize)) {
    return oldSize
  }
  switch (oldSize) {
    case '1em':
      return fontSizes.s
    case '1.2em':
      return fontSizes.l
    case '1.1em':
    default:
      return fontSizes.m
  }
}

const convertGlobalThemeFontSize = oldSize => {
  if (!needsConversion(oldSize)) {
    return oldSize
  }
  switch (oldSize + '') { // + '' -> to convert to str just to be save
    case '16':
      return fontSizes.s
    case '20':
      return fontSizes.l
    case '18':
    default:
      return fontSizes.m
  }
}

// Defensive coding and update button
const updateButton = button => {
  if (button && button.textStyle && button.textStyle['font-size']) {
    button.textStyle['font-size'] = convertButtonFontSize(button.textStyle['font-size'])
    return button
  }

  return button
}

// Loop through all the parts of a section that contains a button
export const sectionMigration = sectionBinding => {
  const updatedSection = sectionBinding

  // Handle buttons:
  if (updatedSection.buttons && updatedSection.buttons.forEach) {
    updatedSection.buttons = updatedSection.buttons.map(button => {
      return updateButton(button)
    })
  }

  // Handle button:
  if (updatedSection.button) {
    updatedSection.button = updateButton(updatedSection.button)
  }

  return updatedSection
}

export const themeMigration = theme => {
  if (theme.fonts) {
    theme.fonts.fontSize = convertGlobalThemeFontSize(theme.fonts.fontSize)
  }
}
