import get from 'lodash/get'

const countdownHelper = (property, classList, data) => {
  const value = get(data.data.root, property)

  return `
    <div
      data-type="countdown"
      data-property="${property}"
      class="countdown-wrapper row ${classList}"
      data-value="${value}"
    >
      <div class="col-6 col-md-3 countdown-days">
        <span class="countdown-unit">0</span>
        <span class="countdown-label">Days</span>
      </div>
      <div class="col-6 col-md-3 countdown-hours">
        <span class="countdown-unit">9</span>
        <span class="countdown-label">Hours</span>
      </div>
      <div class="col-6 col-md-3 countdown-minutes">
        <span class="countdown-unit">59</span>
        <span class="countdown-label">Minutes</span>
      </div>
      <div class="col-6 col-md-3 countdown-seconds">
        <span class="countdown-unit">59</span>
        <span class="countdown-label">Seconds</span>
      </div>
    </div>
  `
}

export default countdownHelper
