import StringParser from './string-parser'
import { hslaImpl, rgbToHsl } from '@eig-builder/core-utils/helpers/color-helper'

const COLOR_LIGHTEN_ADJACENT = 5
const COLOR_DARKEN_ADJACENT = 2

class ColorFunctions {
  static equals (cl1, cl2, treshold = 0) {
    const sqr = a => a * a
    return sqr(cl1[0] - cl2[0]) + sqr(cl1[1] - cl2[1]) + sqr(cl1[2] - cl2[2]) <= treshold
  }

  static darken (r, g, b, a, amount) {
    const hsl = rgbToHsl(r, g, b)

    let hslcolorL = hsl[2]
    if (hslcolorL > 100) {
      hslcolorL = 100
    }

    return hslaImpl(hsl[0], hsl[1], hslcolorL - amount, a)
  }

  static contrastWithAlpha (color1, color2) {
    const contrast = ColorFunctions.contrast(color1[0], color1[1], color1[2], color2[0], color2[1], color2[2])
    const imageAlpha = Math.max(color1[3] || 0, color2[3] || 0)

    // something on an image always has 0.2 contrast
    return imageAlpha * 0.2 + contrast * ((1 - imageAlpha) * 0.8)
  }

  static lighten (r, g, b, a, amount) {
    const hsl = rgbToHsl(r, g, b)

    let hslcolorL = hsl[2]
    if (hslcolorL < 0) {
      hslcolorL = 0
    }

    return hslaImpl(hsl[0], hsl[1], hslcolorL + amount, a)
  }

  static increaseConstrast = (color, compareColor, lightenAmount, darkenAmount) => {
    if (ColorFunctions.colorLightness(...compareColor) < ColorFunctions.colorLightness(...color)) {
      return ColorFunctions.lighten(color[0], color[1], color[2], 1, lightenAmount).slice(0, 3)
    } else {
      return ColorFunctions.darken(color[0], color[1], color[2], 1, darkenAmount).slice(0, 3)
    }
  }

  static makeAdjacentColor = color => {
    if (ColorFunctions.colorLightness(...color) < 0.8) {
      return ColorFunctions.lighten(color[0], color[1], color[2], 1, COLOR_LIGHTEN_ADJACENT).slice(0, 3)
    } else {
      return ColorFunctions.darken(color[0], color[1], color[2], 1, COLOR_DARKEN_ADJACENT).slice(0, 3)
    }
  }

  static transparentize (r, g, b, a, amount) {
    a = Math.max(a - amount, 0.0)

    return [r, g, b, a]
  }

  static opacify (r, g, b, a, amount) {
    a = Math.min(a + amount, 1.0)

    return [r, g, b, a]
  }

  static colorLightness (r, g, b) {
    return (0.299 * r) / 255 + (0.587 * g) / 255 + (0.114 * b) / 255
  }

  static swapTextColor (r, g, b) {
    return (0.2126 * r) / 255 + (0.7152 * g) / 255 + (0.0722 * b) / 255
  }

  static contrast (r1, g1, b1, r2, g2, b2) {
    return Math.abs(ColorFunctions.colorLightness(r1, g1, b1) - ColorFunctions.colorLightness(r2, g2, b2))
  }

  static rgbToHsl (r, g, b) {
    return rgbToHsl(r, g, b)
  }

  static toRgb (rgb) {
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
  }

  static colorStrength (r, g, b) {
    // code from impressly ages
    const hsl = rgbToHsl(r, g, b)
    const brightness = hsl[2] / 100

    const b1 = (0.5 - Math.abs(0.5 - brightness)) * 2.0
    const b2 = Math.max(0, (0.33 - Math.abs(0.33 - brightness)) * 3)

    const strength = b2 * 0.1 + (b1 + 0.2) * (hsl[1] / 100)
    return strength
  }

  static toRgba (rgba) {
    if (rgba.length === 3) {
      return 'rgb(' + rgba.join(',') + ')'
    }
    return 'rgba(' + rgba.join(',') + ')'
  }

  static componentToHex (c) {
    const hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  static rgbToHex (r, g, b) {
    return '#' + ColorFunctions.componentToHex(r) + ColorFunctions.componentToHex(g) + ColorFunctions.componentToHex(b)
  }

  static hexToRgb (hex) {
    let c
    if (/^#([a-f0-9]{3}){1,2}$/i.test(hex)) {
      c = hex.substring(1).split('')
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]]
      }

      c = '0x' + c.join('')
      return [(c >> 16) & 255, (c >> 8) & 255, c & 255]
    }

    return null
  }

  static strToRgb (str) {
    const parser = new StringParser(str)
    const wordStr = parser.getWord()
    if (wordStr == null || wordStr === '') {
      return ColorFunctions.hexToRgb(str)
    }
    if (!parser.endOfString() && parser.getChar() === '(') {
      const params = parser.getParams()
      if (wordStr === 'rgba') {
        if (params.length === 2) {
          return ColorFunctions.strToRgba(params[0])
        } else {
          return [parseInt(params[0]), parseInt(params[1]), parseInt(params[2])]
        }
      } else if (wordStr === 'rgb') {
        return [parseInt(params[0]), parseInt(params[1]), parseInt(params[2])]
      }
    } else {
      // Check for color names??
    }
    return null
  }

  static strToRgba (str) {
    const parser = new StringParser(str)
    const wordStr = parser.getWord()
    if (wordStr == null || wordStr === '') {
      const c = ColorFunctions.hexToRgb(str)
      c[3] = 1
      return c
    }
    if (!parser.endOfString() && parser.getChar() === '(') {
      const params = parser.getParams()
      if (wordStr === 'rgba') {
        if (params.length === 2) {
          const rgb = ColorFunctions.strToRgba(params[0])
          return [rgb[0], rgb[1], rgb[2], parseFloat(params[1])]
        } else {
          return [parseInt(params[0]), parseInt(params[1]), parseInt(params[2]), parseFloat(params[3])]
        }
      } else if (wordStr === 'rgb') {
        return [parseInt(params[0]), parseInt(params[1]), parseInt(params[2]), 1]
      }
    } else {
      // Check for color names??
    }
    return null
  }
}

export default ColorFunctions
