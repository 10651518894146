import Layouts from 'editor/managers/layouts'

//////////////////
///////////////////////////////////////////////////////////////
/////////

const getFeatureSettings = (featureName) => {
///////////////////
  if (window._featureSettings) {
    return window._featureSettings[featureName]
  }
//////////
////////////////////////////////////////////////////////
/////////////////////////////////
///////////
}

export {
  getFeatureSettings,
////////////////////
////////////////
///////////
}
