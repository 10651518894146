import replaceDiacritics from 'core/helpers/data/replace-diacritics'

export const classes = (baseClass, ...mixins) => {
  class Base extends baseClass {
    constructor (...args) {
      super(...args)
      mixins.forEach((Mixin) => {
        copyProps(this, (new Mixin()))
      })
    }
  }

  const copyProps = (target, source) => { // this function copies all properties and symbols, filtering out some special ones
    Object.getOwnPropertyNames(source)
      .concat(Object.getOwnPropertySymbols(source))
      .forEach((prop) => {
        if (!prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/)) { Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop)) }
      })
  }

  mixins.forEach((mixin) => { // outside contructor() to allow aggregation(A,B,C).staticFunction() to be called etc.
    copyProps(Base.prototype, mixin.prototype)
    copyProps(Base, mixin)
  })
  return Base
}

const toUriFriendlyName = (value, allowSlashes = true) => {
  if (!value) {
    return value
  }

  value = replaceDiacritics(value).replace(/[\u0300-\u036f]/g, '')
  value = value
    .replace(/[^-/a-zA-Z0-9]+/g, '-')
    .toLowerCase()
    .trim('/')
    .trim('-')
  if (!allowSlashes) {
    value = value.replace('/', '-')
  } else {
    // convert multiple slashes to one
    value = value.replace(/\/+/g, '/')
  }

  // convert multiple dashes to one
  value = value.replace(/-+/g, '-')

  return value
}

const toDomainFriendlyName = (value, allowSlashes = true) => {
  return toUriFriendlyName(value, false)
}

const getName = font => {
  return (font.fontPairs ? (Array.isArray(font.fontPairs) ? font.fontPairs[0] : {}) : font || {}).name
}

const getWeight = font => {
  return (font.fontPairs ? (Array.isArray(font.fontPairs) ? font.fontPairs[0] : {}) : font || {}).weight
}

const getWeightNumber = font => {
  const weight = getWeight(font)

  if (!isNaN(parseInt(weight)) || !weight) {
    return weight
  }

  // weight could be 400italic, the css weight needs to be 400, the googlefont url weight = 400italic
  return (weight.match(/\d+/g) || [])[0]
}

const getFontAsString = font => {
  const weight = getWeight(font)
  let fontWeight = weight ? `:${weight}` : ''

  const name = getName(font)
  if (name.indexOf(':') > 0) {
    fontWeight = ''
  }

  return name.replace(/ /g, '+') + fontWeight
}

const fontObjToUrl = font => {
  return `https://fonts.googleapis.com/css?family=${getFontAsString(font)}`
}

const fontObjectsToUrl = fonts => {
  return `https://fonts.googleapis.com/css?family=${getFontAsString(fonts.body)}|${getFontAsString(fonts.heading)}`
}

const getFontName = font => {
  const name = getName(font)
  const index = name.indexOf(':')
  if (index > 0) {
    return name.substr(0, index)
  }
  return name
}

const fontObjToStyleAtt = font => {
  const letterSpacing = font.letterSpacing ? ` letter-spacing: ${font.letterSpacing}px;` : ''
  return `font-family: '${getFontName(font)}' !important; font-weight: ${getWeightNumber(font)};${letterSpacing}`
}

const decodeLocationToUriPathAndParams = (allPossibleUriPaths, locationUriPath) => {
  let uri = locationUriPath
  let slashIndex = 0

  if (uri) {
    // trim down last uri part until it matches an existing page
    while (allPossibleUriPaths.indexOf(uri) < 0 && (slashIndex = uri.lastIndexOf('/')) > 0) {
      uri = uri.substr(0, slashIndex)
    }
  }

  const hasMatch = slashIndex >= 0

  return {
    hasMatch,
    uriPath: uri,
    routeParams: hasMatch ? locationUriPath && locationUriPath.substr(uri.length + 1).split('/') : []
  }
}

export { toUriFriendlyName, toDomainFriendlyName, fontObjToUrl, fontObjToStyleAtt, getFontName, decodeLocationToUriPathAndParams, fontObjectsToUrl }
