class LazyVideosMixin {
  initializeLazyVideos = videos => {
    if (!('IntersectionObserver' in window)) {
      Array.from(videos).forEach(video => this.preloadVideo(video))
    } else {
      this.observer = new window.IntersectionObserver(this.onIntersection, {
        rootMargin: '50px 0px',
        threshold: 0.01
      })
      videos.forEach(video => {
        this.observer.observe(video)
      })
    }
  }

  onIntersection = entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        this.observer.unobserve(entry.target)
        this.preloadVideo(entry.target)
      }
    })
  }

  preloadVideo (video) {
    if (video.tagName === 'DIV') {
      if (video.dataset.image) {
        const style = (video.getAttribute('style') || '').trim()
        video.setAttribute('style', style + '; ' + video.dataset.image)
      } else if (video.dataset.src) {
        video.setAttribute('src', video.dataset.src)
      }
    } else if (video.tagName === 'VIDEO') {
      const source = video.querySelector('source')
      if (source.dataset.src) {
        source.setAttribute('src', source.dataset.src)
        video.load()
        video.play()
      }
    }
  }

  bindLayout (element, data) {
    if (this.lazyImageSelector) {
      const videos = [...element.querySelectorAll(this.lazyImageSelector)]
      this.initializeLazyVideos(videos)
    }
  }
}

export default LazyVideosMixin
