import CoreListControl from 'core/editor/controls/list-control'

// Templates
import LayoutList from '../templates/list-control.tpl'
import LayoutListSwiper from '../templates/list-control-swiper.tpl'
import LayoutListSwipe from '../templates/list-control-swipe.tpl'

class ListControl extends CoreListControl {
  constructor (controller, view) {
    let Layout = LayoutList
    switch (view) {
      case 'swiper':
        Layout = LayoutListSwiper
        break
      case 'gallery':
        Layout = LayoutListSwipe
        break
    }
    super(controller, Layout, view)
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)
  }
}
export default ListControl
