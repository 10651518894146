// The template for the custom select box
const template = (classList, listItems, defaultValue) => `<div class='ee-select-box ${classList}' data-selected='none' data-value="${defaultValue}">
  <div class='ee-select-box-innerwrapper ee-toggle-dropdown'>
    <div class='ee-select-box-field ee-toggle-dropdown'>
      <span class='ee-toggle-dropdown'>...</span>
      <i class='ee-dropdown-arrow fa fa-caret-down ee-toggle-dropdown'></i>
    </div>
    <div class="ee-dropdown select-box-dropdown-container">
      <ul>
        ${listItems}
      </ul>
    </div>
  </div>
</div>`

// helper function for building the list items
const buildListItems = items => {
  let builtItems = ''

  if (!items) {
    return builtItems
  }

  items.forEach(item => {
    builtItems += `<li class="ee-select-item" data-value='${item.value}'> ${item.title} </li>`
  })

  return builtItems
}

// Exposed interface for building the selectbox
const selectBox = (config, classList, defaultValue, data) => {
  const listItems = buildListItems(config)

  return template(classList, listItems, defaultValue)
}

export default selectBox
