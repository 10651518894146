/* eslint import/no-duplicates: 0 */
import { getFeatureSettings as coreGetFeatureSettings } from 'core/editor/managers/features/feature-helpers'

////////////////
/////////////////////////////////////////////////
/////////

//////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////

const getFeatureSettings = featureName => coreGetFeatureSettings(featureName)

const getIsOnboarding = () => {
  if (IS_EDITOR) {
    return !Store().getState().api.siteId
  }
  return false
}

export {
  getFeatureSettings,
  getIsOnboarding,
////////////////////
////////////////
///////////
}
