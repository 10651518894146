import buildClassAttribute from './build-class-attribute'
import buildDefaultAttributes from './build-default-attributes'
import buildNewTabAttribute from './build-newtab-attribute'
import buildEditableAttribute from './build-editable-attribute'

import GlobalProperties from 'core/helpers/global/global-properties'

import { getHref, buildInlineStyle } from '../utils'

import missingLinkIconSVG from '../assets/missing-link-icon.svg'
const ESCAPED_CHARS_MINI = {
  // eslint-disable-line
  '<': '',
  '>': '',
  '{': '',
  '}': '',
  '(': '',
  ')': ''
}
const linkSanitizer = string => {
  let newValue = string

  if (newValue && newValue.length) {
    const removeIllegalCharacters = unsafeChar => {
      return ESCAPED_CHARS_MINI[unsafeChar]
    }

    newValue = newValue.replace(/[<>{}()]/g, removeIllegalCharacters)
  }

  return newValue
}

const linkButtonHelper = (prop, classList, editableProperty, controlType, index, binding) => {
  // Build the list of classes needed for the element
  const classAttribute = buildClassAttribute(prop, classList)

  // tabindex is used to disable certain bootstrap styling which has 'not' selectors on tabindex
  const regularAttributes = buildDefaultAttributes(controlType, index)

  // Build the href attribute - based on the type of link we need to look in a different place
  const linkHref = linkSanitizer(getHref(prop))
  const hrefAttribute = `data-href="${linkHref}" href="${linkHref}"`

  const brokenLink = !linkHref || linkHref === '##' || linkHref === '#'
  const brokenLinkIcon = brokenLink
    ? `<div class="button-link-broken" data-balloon-length="medium" data-balloon-pos="up" data-balloon="${GlobalProperties.localize(
      'designer.editor.handlebars.noLink'
    )}">
      <div style="background-image: url(${missingLinkIconSVG});" class="button-link-broken-icon"></div>
    </div>`
    : ''

  // See if we need to open the link in a new tab
  const newTabAttribute = buildNewTabAttribute(prop)

  // Is an editable element
  const editableAttribute = buildEditableAttribute(editableProperty)

  // Style attribute
  const styleAttribute = prop.textStyle ? ` style="${buildInlineStyle(prop.textStyle)}"` : ''

  const attributes = `${classAttribute} ${regularAttributes} ${!brokenLink && hrefAttribute} ${newTabAttribute} ${editableAttribute} ${styleAttribute}`
  const icon = 'icon' in prop && prop.icon.code ? `<i ${styleAttribute} class="fa ${prop.icon.code}"> </i>` : ''
  const textStyling = prop.textStyle ? buildInlineStyle(prop.textStyle) : ''

  if ('title' in prop) {
    if (editableProperty) {
      if (GlobalProperties.editable && !prop.title) {
        prop.title = '&nbsp;'
      }
      const dataProp = index !== undefined && editableProperty === 'buttons' ? `${editableProperty}.${index}.title` : `${editableProperty}.title`

      return `<a ${attributes}>
        ${brokenLinkIcon}
        ${icon}
        <div style="${textStyling}" class="${prop.title ? 'button-has-title' : 'button-has-no-title'}">${prop.title}<div
          data-editable="button"
          data-prop="${dataProp}"
          style="display:none;">
        </div></div>
      </a>`
    } else {
      return `<a ${attributes}>${icon}<div>${prop.title}</div></a>`
    }
  }
}

export default linkButtonHelper
