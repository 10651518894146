const makeSelector = (sectionClass, selectorText) => {
  let selector
  if (selectorText.indexOf(',') >= 0) {
    selector = selectorText
      .split(',')
      .map(i => `.${sectionClass}${i.trim()}`)
      .join(', ')
  } else {
    selector = `.${sectionClass}${selectorText}`
  }

  // hack to transform a :after to ::after (otherwise we can't update the rule and we'll insert a new one every time something changes)
  // this should be solved in the templates or on the server
  if (selector.indexOf(':before') >= 0 && selector.indexOf('::before') < 0) {
    selector = selector.replace(':before', '::before')
  }
  if (selector.indexOf(':after') >= 0 && selector.indexOf('::after') < 0) {
    selector = selector.replace(':after', '::after')
  }
  return selector
}

export default makeSelector
