import each from 'lodash/each'
import ColorFunctions from '../color-functions'
import SassFunctionParser from 'core/editor/helpers/sass-function-parser'
import makeSelector from './make-selector'

class StyleSheet {
  constructor (layout, isBaseStyling) {
    this.layout = layout
    this.isBaseStyling = isBaseStyling
    this.activePalettePerCssId = {}
  }

  parseColors (colorModel) {
    try {
      if (!colorModel) {
        return
      }

      const colorPaletteHash = colorModel.colorPaletteHash()
      let cssId = colorModel.backgroundController.getCssId()

      if (this.activePalettePerCssId[cssId] === colorPaletteHash) {
        return // already the active rule, don't regenerate css
      }
      this.activePalettePerCssId[cssId] = colorPaletteHash

      colorModel.internalParse()

      if (this.isBaseStyling) {
        cssId = `${cssId} ` // add a space for the selector
      }

      let hasTextShadow = false
      each(this.layout.style_variables, variable => {
        if (!variable.parsedVariableName) {
          variable.parsedVariableName = colorModel.parseColorVariable(variable.variable_name)
        }
        if (variable.condition_text && variable.condition_text.indexOf('@') >= 0) {
          variable.condition_text = variable.condition_text.substr(7) // strip @media, maybe nicer to do on server
        }

        const selector = makeSelector(cssId, variable.selector_text)

        colorModel.walkColorTree(variable.parsedVariableName, (parsedVarStep, currentNode, previousColor) => {
          const color = currentNode._color
          if (!color) {
            return
          }
          const rgba = 'rgba(' + color.join(',') + ',' + parsedVarStep.alpha + ')' // make color with alpha
          const parsedColor = SassFunctionParser.parse(variable.style_value, variable.variable_name, rgba)

          if (parsedVarStep.isText) {
            const textOnBackground = variable.parsedVariableName[0].isText
            const contrast = textOnBackground ? ColorFunctions.contrastWithAlpha(previousColor, color) : 1
            const offset = 0.3
            const contrastCheck = 0.4
            const maxAlpha = 0.7
            if (contrast < contrastCheck && ColorFunctions.colorLightness(...color) > 0.5) {
              const shadowAlpha = Math.min(maxAlpha, offset + ((contrastCheck - contrast) / contrastCheck) * 1)
              this.setCssRule(
                variable.condition_text,
                'text-shadow',
                selector,
                `0px 1px 3px rgba(0,0,0,${shadowAlpha.toFixed(3)})`,
                ''
              )
              hasTextShadow = true
            } else {
              this.setCssRule(variable.condition_text, 'text-shadow', selector, 'none', '')
            }
          }

          this.setCssRule(variable.condition_text, variable.style_declaration, selector, parsedColor, color)

          if (parsedVarStep.name === 'card') {
            const boxShadowValue = colorModel.hasWhiteBackground() ? 'inset 0 0 0 1px rgba(0, 0, 0, 0.1)' : ''
            this.setCssRule(variable.condition_text, 'box-shadow', selector, boxShadowValue, color)
          }
        })
      })

      if (hasTextShadow) {
        each(this.layout.style_variables, variable => {
          const parsed = variable.parsedVariableName

          // reset shadow of all other text color shadows because the text1 coloring usally has other text classes as nested divs
          if (parsed.length > 1 && parsed[parsed.length - 1].isText) {
            const selector = makeSelector(cssId, variable.selector_text)
            this.setCssRule(variable.condition_text, 'text-shadow', selector, 'none', '')
          }
        })
      }
    } catch (ex) {
      console.error(`error parsing css for section layout: ${this.layout.id}`)
      console.error(ex)
    }
  }
}

export default StyleSheet
