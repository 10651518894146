import SmartArray from './smart-array'
import Dictionary from './dictionary'
import Notification from './notification'
import NotificationsConfig from './config.json'

class Notifications {
  static idCounter = 0

  constructor () {
    this.config = NotificationsConfig
    this.notifications = new Dictionary()
    this.stack = new SmartArray()
  }

  showNotification (opts) {
    const notificationId = Notifications.idCounter
    Notifications.idCounter++

    const notification = new Notification(notificationId, opts, this)

    if (!this.notifications.exists(notificationId)) {
      this.notifications.set(notificationId, notification)
      notification.index = this.stack.push(notification)

      notification.show(() => {
        notification.timeout = setTimeout(() => {
          this.closeNotification(notificationId)
        }, this.config.visibilityTimeout)
      })
    }
  }

  closeNotification (id) {
    const notification = this.notifications.get(id)
    if (notification) {
      clearTimeout(notification.timeout)
      this.notifications.remove(id)
      this.stack.removeByIndex(notification.index)

      // Move all newer notifications one position up.
      this.notifications.forEach(other => {
        if (other.index > notification.index) {
          const currentTop = Number(other.wrapper.style.top.replace('px', ''))
          other.setTop(currentTop - 56)
        }
      })

      notification.hide()
    }
  }
}

export default Notifications
