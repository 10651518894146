import CoreContentController, { setDefaultValues } from 'core/editor/controllers/content-controller'

class ContentController extends CoreContentController {
  getValue = (model) => {
    const options = {}

    if (this.imports.isPublisher) {
      setDefaultValues(this.model, this.getSectionController())
    }

    if (!this.isRuntime()) {
      if (options.fixedNavigation) {
        options.fixedNavigation = false
      }
    }
    return this.getInnerValue(model, options)
  }

  updateView (view, model, paths) {
    const keys = Object.keys(paths)
    if (keys.length && keys.every(i => i.indexOf('img.') === 0)) {
      const src = model.img.temp_value || model.img.value
      if (this.control.domElement) {
        this.control.domElement.style.backgroundImage = `url(${src})`
        const table = this.control.domElement.querySelector('table')
        if (table) {
          table.setAttribute('background', src)
          table.style.backgroundImage = `url(${src})`
        }
      }
    } else {
      super.updateView(view, model, paths)
    }
  }
}
export default ContentController
