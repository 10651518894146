import StyleSheet from './style-sheet'

class StringStyleSheet extends StyleSheet {
  constructor (stylesheetKey, css, isBaseStyling, layout, colorModel) {
    super(layout, isBaseStyling)

    this.stylesheetKey = stylesheetKey
    this.css = css
    this.imports = []

    if (layout) {
      if (layout.imports) {
        this.imports = layout.imports
      }

      if (layout.style_variables) {
        this.parseColors(colorModel)
      }
    }
  }

  updateMediaQueriesWidths () {
    // not implemented
  }

  getCss () {
    return this.css
  }

  getImports () {
    return this.imports
  }

  dispose () {
    // not implemented
  }

  setCssRule (conditionText, styleDeclaration, selector, value, rawColor) {
    const theRule = `${styleDeclaration}: ${value}`
    let ruleStr = `${selector} { ${theRule}; }`
    if (conditionText) {
      ruleStr = `@media ${conditionText} { ${ruleStr} }`
    }
    this.css += ` ${ruleStr}`
  }
}

export default StringStyleSheet
