function testLocalStorage () {
  const test = 'a'
  try {
    window.localStorage.setItem(test, test)
    window.localStorage.removeItem(test)
    return true
  } catch (ex) {
    return false
  }
}

const supportsLocalStorage = testLocalStorage()

class LocalStorageHelper {
  constructor () {
    this.supportsLocalStorage = supportsLocalStorage
  }

  set (key, value) {
    window.localStorage.setItem(key, value)
  }

  get (key) {
    return window.localStorage.getItem(key)
  }
}

export default LocalStorageHelper
