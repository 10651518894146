class LogError {
  static log (message, ex, obj) {
    if (message) {
      console.error(message, obj)
    }
    if (ex) {
      console.error(ex, obj)
    }
  }
}

export default LogError
